import {
  Button,
  ButtonVariant,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { Fragment, ReactNode, useEffect } from "react";
import NavigationPrompt from "react-router-navigation-prompt";
import { useUnload } from "src/hooks/useUnload";
import { ObjectState } from "src/utils/formState";

export type FormActionsProps<F> = {
  mode: "create" | "read" | "update";
  formState: ObjectState<F>;
  entityType?: string;
  entityName?: string;
  onSave: () => Promise<unknown>;
  onCancel: () => void;
  onEdit?: () => void;
  editDisableReason?: string;
  onDelete?: () => Promise<unknown>;
  deleteDisabledReason?: string;
  deleteMessage?: string | ReactNode;
  /** When defined will override standard delete confirmation modal */
  deleteModalContent?: ModalProps["content"];
  secondaryButton?: "cancel" | "delete" | "none";
  /** Defaults to "Create" or "Save", but you can override i.e. if you really want "Add". */
  primaryLabel?: string;
  secondaryLabel?: string;
  reverseOrder?: boolean;
  secondaryVariant?: ButtonVariant;
};

/** Whether the user is reading, creating, updating an entity. */
export type FormMode = "create" | "update" | "read";

/** Provides edit/save/delete/cancel buttons for a FormState-driven form. */
export function FormActions<F>(props: FormActionsProps<F>) {
  const {
    mode,
    onSave = async () => {},
    onCancel = () => {},
    onEdit,
    onDelete,
    entityName = "the entry",
    entityType = "entry",
    formState,
    deleteDisabledReason,
    deleteMessage,
    deleteModalContent,
    reverseOrder = false,
    secondaryButton,
    primaryLabel,
    secondaryLabel,
    editDisableReason,
    secondaryVariant = "tertiary",
  } = props;
  const { openModal, closeModal } = useModal();
  const testIds = useTestIds({}, "formActions");

  // We use a function b/c this gets passed to NavigationPrompt that probably needs the latest
  // value from our formState (i.e. after formState.commitChanges()) w/o waiting for mobx to reactively
  // through an updated props value.
  const isDirty = () => formState.dirty;

  // Setting up events to listen for browser navigation to add confirmation if `promptOnLeave` is true.
  // Unfortunately we need to handle Browser routing and React routing separately.
  // `useUnload` hooks into native browser prompts. This handles full page reloads that React-Router cannot hook into.
  useUnload((e: BeforeUnloadEvent) => {
    if (isDirty()) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  // We check the .dirty via a proxy so need to be reactive
  return (
    <Observer>
      {() => {
        const primaryBtn =
          mode === "read" ? (
            onEdit && (
              <Button
                variant="secondary"
                label="Edit"
                data-testid="edit"
                onClick={onEdit}
                disabled={editDisableReason}
              />
            )
          ) : (
            <Button
              label={primaryLabel ?? (mode === "create" ? "Create" : "Save")}
              onClick={async () => {
                if (formState.canSave()) {
                  const promise = onSave();
                  // If the promise resolves successful, mark the form state
                  await promise;
                  formState.commitChanges();
                }
              }}
              data-testid="save"
              // Should we check `formState.valid` here?
              // It seems intuitive that "if the form isn't valid, don't allow clicking Save,
              // but right now form fields only show errors if they are touched, and we have
              // a lot of tests that use "click Save" as a way of doing "touch all fields".
              disabled={!isDirty()}
            />
          );

        const secondaryBtn =
          secondaryButton === "none" ? (
            <Fragment />
          ) : secondaryButton === "delete" || (mode === "read" && (onDelete || deleteModalContent)) ? (
            <Button
              variant="danger"
              label="Delete"
              disabled={deleteDisabledReason}
              data-testid="deleteBtn"
              onClick={() =>
                openModal({
                  content: deleteModalContent || (
                    <>
                      <ModalHeader>{`Delete ${entityType}`}</ModalHeader>
                      <ModalBody>{deleteMessage || `This will delete ${entityName} and cannot be undone.`}</ModalBody>
                      <ModalFooter>
                        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
                        <Button
                          label="Delete"
                          variant="danger"
                          data-testid="confirmDelete"
                          onClick={async () => {
                            // Awaiting this so that the modal doesn't close until the delete is complete and a loading icon is shown.
                            (await onDelete?.()?.finally(closeModal)) ?? closeModal();
                          }}
                        />
                      </ModalFooter>
                    </>
                  ),
                })
              }
            />
          ) : (
            (secondaryButton === "cancel" || mode !== "read") && (
              <Button
                variant={secondaryVariant}
                label={secondaryLabel ?? "Cancel"}
                onClick={() => {
                  formState.revertChanges();
                  onCancel();
                }}
              />
            )
          );

        return (
          <Fragment>
            <div css={Css.df.aic.gap1.$}>
              {reverseOrder ? (
                <Fragment>
                  {primaryBtn} {secondaryBtn}
                </Fragment>
              ) : (
                <Fragment>
                  {secondaryBtn} {primaryBtn}
                </Fragment>
              )}
            </div>

            {/* React Router implementation of confirmation dialog. */}
            <NavigationPrompt
              // Only prompt if we're navigating away from the current page.
              when={(currentLocation, nextLocation) =>
                isDirty() && !nextLocation?.pathname.startsWith(currentLocation.pathname)
              }
            >
              {({ onConfirm, onCancel }) => (
                <ConfirmLeaveModal onConfirm={onConfirm} onCancel={onCancel} formState={formState} />
              )}
            </NavigationPrompt>
          </Fragment>
        );
      }}
    </Observer>
  );
}

type ConfirmLeaveModalProps = {
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
  formState: ObjectState<any>;
};

export function ConfirmLeaveModal({ onConfirm, onCancel, formState }: ConfirmLeaveModalProps) {
  const { openModal, closeModal } = useModal();
  // useEffect to immediately open the modal.
  useEffect(
    () => {
      openModal({
        content: (
          <>
            <ModalHeader>Leave page?</ModalHeader>
            <ModalBody>
              <p>All changes you've made will be lost.</p>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="tertiary"
                label="Continue Editing"
                onClick={() => {
                  closeModal();
                  onCancel();
                }}
              />
              <Button
                label="Leave"
                variant="primary"
                onClick={() => {
                  onConfirm();
                  formState.revertChanges();
                  closeModal();
                }}
              />
            </ModalFooter>
          </>
        ),
      });

      return () => closeModal();
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return <></>;
}
