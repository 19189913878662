import { Button, Css, SuperDrawerWidth, useComputed, useGridTableApi, useSuperDrawer, useToast } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { SaveExpenseAllocationInput, useSaveExpenseAllocationsMutation } from "src/generated/graphql-types";
import { ChangeEventForm } from "src/routes/projects/expenses/components/ChangeEventForm";
import {
  ExpenseAllocationRow,
  ExpenseAllocationTable,
} from "src/routes/projects/expenses/components/ExpenseAllocationTable";
import { ExpenseTableRow, ExpensesTableInput } from "src/routes/projects/expenses/components/ExpensesTable";
import { LineItemsMerged } from "src/routes/projects/expenses/components/LineItemsMerged";
import { formatCentsToPrice } from "src/utils";

type ExpenseCellAllocationOptionsProps = {
  row: ExpenseTableRow;
  formState: ObjectState<ExpensesTableInput>;
  refetchHeaderQuery: VoidFunction;
};

export function ExpenseCellAllocationOptions(props: ExpenseCellAllocationOptionsProps) {
  const { formState, row, refetchHeaderQuery } = props;
  const { showToast } = useToast();
  const tableApi = useGridTableApi<ExpenseAllocationRow>();
  const { closeDrawer, openInDrawer } = useSuperDrawer();
  const [saveExpenseAllocations] = useSaveExpenseAllocationsMutation();
  // We assign a different tableApi for each expense allocation table, so we can then track selected rows on ExpenseCellDetail
  row.tableApi ??= tableApi;

  const openChangeEventForm = (row: ExpenseTableRow) => {
    openInDrawer({
      content: (
        <ChangeEventForm
          onCreateLineItem={(pi) => {
            formState.expenseAllocations.add({
              id: undefined,
              amountInCents: undefined,
              expenseId: row.id,
              projectItemId: pi.id,
            });
          }}
          expense={row}
          closeDrawer={closeDrawer}
        />
      ),
      width: SuperDrawerWidth.Small,
    });
  };

  const handleMerge = async () => {
    const selectedProjectItemIds = tableApi.getSelectedRowIds("projectItem");
    const inputs: SaveExpenseAllocationInput[] = formState.expenseAllocations.rows
      .filter(
        // Only save existing rows or new rows that are selected with an amount
        (row) => row.id.value || (row.amountInCents.value && selectedProjectItemIds.includes(row.projectItemId.value!)),
      )
      .map((row) => ({
        amountInCents: row.amountInCents.value ?? 0,
        expenseId: row.expenseId.value,
        projectItemId: row.projectItemId.value,
        commitmentLineItemId: row.commitmentLineItemId.value,
        id: row.id.value,
        delete: (row.id.value && !selectedProjectItemIds.includes(row.projectItemId.value!)) || undefined,
      }));

    const result = await saveExpenseAllocations({ variables: { input: { expenseAllocations: inputs } } });

    if (!result.errors) {
      const { rows } = formState.expenseAllocations;
      const findRowItem = (projectItemId: string) => rows.find((item) => item.projectItemId.value === projectItemId);

      const deleted = inputs.filter((i) => i.delete);
      deleted.forEach(({ projectItemId }) => {
        const toUpdateAsDeleted = findRowItem(projectItemId!);
        if (toUpdateAsDeleted) {
          // Clear ID
          toUpdateAsDeleted.set({ ...toUpdateAsDeleted.value, id: undefined });
        }
      });

      const saved = result.data?.saveExpenseAllocations.expenseAllocations ?? [];
      saved.forEach(({ id, projectItem: { id: projectItemId } }) => {
        const toUpdate = findRowItem(projectItemId);
        if (toUpdate) {
          toUpdate.set({ ...toUpdate.value, id });
        }
      });

      const wasUnallocated = formState.merged.value && formState.expenseAllocations.value.length === deleted.length;
      formState.merged.set(wasUnallocated ? false : !formState.merged.value);
    }

    formState.edittingLines.set(false);
    formState.commitChanges();

    // Refetch the header query to update the total amount allocated
    refetchHeaderQuery();
  };

  const { expenseRemainingInCents, selectedAmountInCents, selectedExpenseRows } = useComputed(() => {
    const selectedProjectItemIds = tableApi.getSelectedRowIds("projectItem");
    const selectedExpenseRows = formState.expenseAllocations.rows.filter((r) =>
      selectedProjectItemIds.includes(r.projectItemId.value!),
    );
    const selectedAmountInCents = selectedExpenseRows.sum((pi) => pi.amountInCents.value || 0);
    const expenseRemainingInCents = row.amountInCents - selectedAmountInCents;
    return { selectedExpenseRows, selectedAmountInCents, expenseRemainingInCents };
  }, [formState.expenseAllocations.rows, row.amountInCents, row.tableApi.getSelectedRowIds]);

  const canUndoAllocation = selectedExpenseRows.isEmpty && row.allocations.nonEmpty;
  return (
    <Observer>
      {() => (
        <>
          {formState.merged.value && !formState.edittingLines.value ? (
            <div css={Css.df.aic.$}>
              <LineItemsMerged
                itemsCount={selectedExpenseRows.length || row.allocations.length}
                editExpenseItems={() => formState.edittingLines.set(true)}
              />
            </div>
          ) : (
            <div css={Css.gap2.w100.br8.bgGray100.df.fdc.$}>
              <ExpenseAllocationTable
                expense={row}
                formState={formState}
                tableApi={tableApi}
                totalAmountInCents={selectedAmountInCents}
              />

              <div css={Css.borderRadius("0px 0px 8px 8px").bgGray200.df.p1.gap1.w100.addIn("button", Css.f1.jcc.$).$}>
                <Button
                  icon="plus"
                  variant="secondary"
                  label="Add New Line Item with Change Event"
                  disabled={!row.hasCostCode}
                  onClick={() => openChangeEventForm(row)}
                />
                {row.potentialProjectItems.nonEmpty && (
                  <Button
                    variant="primary"
                    label={canUndoAllocation ? "Undo Allocation" : "Merge with Selected Line Items"}
                    onClick={async () => {
                      await handleMerge();

                      // When click to undo allocation, we want to show a toast
                      if (canUndoAllocation) {
                        showToast({ message: `Expense ${row.id} successfully unallocated`, type: "success" });
                      }
                    }}
                    disabled={
                      canUndoAllocation
                        ? undefined
                        : expenseRemainingInCents !== 0
                          ? `You can merge items once you 1) select one or more line items and 2) their
                      actuals equal ${formatCentsToPrice(expenseRemainingInCents)}`
                          : false
                    }
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </Observer>
  );
}
