import {
  BoundCheckboxField,
  BoundNumberField,
  Button,
  Css,
  IconButton,
  StaticField,
  TabsWithContent,
  useRightPane,
} from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useState } from "react";
import { getStage } from "src/context";
import {
  BidPackageBidItemFragment,
  BidPackageDetailPage_BidContractLineItemFragment,
  CostType,
  Maybe,
  NamedFragment,
  SaveBidContractLineItemInput,
  useEditBidLineItemMutation,
  useEditBidRightPane_SaveBidContractMutation,
} from "src/generated/graphql-types";
import { DateOnly } from "src/utils/dates";
import { NewBidContractParams } from "./ProrateBidModal";

type EditBidRightPaneProps = {
  bidItem: BidPackageBidItemFragment;
  bidContractLineItem?: Maybe<BidPackageDetailPage_BidContractLineItemFragment>;
  revisionId?: Maybe<string>;
  trade: NamedFragment;
  newBidContractParams?: Maybe<
    Pick<NewBidContractParams, "developmentIds" | "bidRequestId" | "type" | "bidContractId">
  >;
};

type TabValue = "details" | "history";

export function EditBidRightPane({
  bidItem,
  bidContractLineItem,
  revisionId,
  trade,
  newBidContractParams,
}: EditBidRightPaneProps) {
  const { closeRightPane } = useRightPane();
  const [selectedTab, setSelectedTab] = useState<TabValue>("details");

  return (
    <div css={Css.p4.$}>
      <div css={Css.lgSb.mb1.$}>
        Edit Bid <span css={Css.gray700.$}>— {trade.name}</span>
      </div>
      <div css={Css.absolute.right3.top3.$}>
        <IconButton data-testid="closePane" icon="x" onClick={closeRightPane} />
      </div>
      <TabsWithContent
        tabs={[
          {
            name: "Details",
            value: "details",
            render: () => (
              <DetailsTab
                bidItem={bidItem}
                bidContractLineItem={bidContractLineItem}
                revisionId={revisionId}
                trade={trade}
                newBidContractParams={newBidContractParams}
              />
            ),
          },
          { name: "History", value: "history", render: () => <HistoryTab /> },
        ]}
        selected={selectedTab}
        onChange={setSelectedTab}
      />
    </div>
  );
}

function DetailsTab({ bidContractLineItem, bidItem, revisionId, newBidContractParams, trade }: EditBidRightPaneProps) {
  const { closeRightPane } = useRightPane();
  const [editBidLineItem] = useEditBidLineItemMutation();
  const [saveBidContract] = useEditBidRightPane_SaveBidContractMutation();

  const formState = useFormState({
    config: formConfig,
    init: {
      input: { bidContractLineItem },
      map: (data) => {
        return {
          id: data.bidContractLineItem?.id,
          totalCostInCents: data.bidContractLineItem?.totalCostInCents,
          isTaxExempt: data.bidContractLineItem?.isTaxExempt ?? bidItem.costType.code !== CostType.Materials,
          quantity: 1,
        };
      },
    },
  });

  return (
    <div>
      <div css={Css.xsSb.mb2.$}>Item Details:</div>
      <div css={Css.df.gap4.$}>
        <div css={Css.df.fdc.gap1.$}>
          <div css={Css.xsMd.$}>Code</div>
          <div css={Css.xs.$}>{bidItem.items[0].costCode.number}</div>
        </div>
        <div css={Css.df.fdc.gap1.$}>
          <div css={Css.xsMd.$}>Name</div>
          <div css={Css.xs.$}>{bidItem.displayName}</div>
        </div>
      </div>
      <div css={Css.my4.w100.hPx(1).bgGray200.$} />
      <div css={Css.xsSb.mb2.$}>Change Details:</div>
      <div css={Css.df.gap1.$}>
        <div css={Css.df.fdc.gap1.w100.$}>
          <div css={Css.xsMd.gray700.$}>UoM</div>
          <StaticField labelStyle="hidden" label="" value={bidItem.unitOfMeasure.shortName} />
        </div>
        <div css={Css.df.fdc.gap1.w100.$}>
          <div css={Css.xsMd.$}>Unit Cost</div>
          <Observer>
            {() => (
              <BoundNumberField
                type="cents"
                compact
                field={formState.totalCostInCents}
                errorMsg={formState.totalCostInCents.focused ? formState.totalCostInCents.errors.join(", ") : undefined}
                labelStyle="hidden"
              />
            )}
          </Observer>
        </div>
        {getStage() !== "prod" && (
          <div css={Css.df.fdc.gap1.w100.$}>
            <div css={Css.xsMd.gray700.$}>Tax Exempt</div>
            <Observer>{() => <BoundCheckboxField field={formState.isTaxExempt} label="" />}</Observer>
          </div>
        )}
      </div>

      <div css={Css.absolute.bottom0.left0.right0.bt.bcGray200.df.$}>
        <div css={Css.ma.py("18px").$}>
          <Observer>
            {() => (
              <Button
                label="Save Updates"
                disabled={!formState.totalCostInCents.value && "Unit cost is required"}
                variant="text"
                onClick={async () => {
                  const { id, totalCostInCents, isTaxExempt } = formState.value;

                  let newRevisionId: string | undefined = undefined;
                  if (!revisionId) {
                    const res = await saveBidContract({
                      variables: {
                        input: {
                          tradePartnerId: trade.id,
                          startDate: new DateOnly(new Date()),
                          ...newBidContractParams,
                        },
                      },
                    });
                    newRevisionId = res.data?.saveBidContract.bidContract.currentRevision.id;
                  }

                  await editBidLineItem({
                    variables: {
                      input: {
                        id,
                        totalCostInCents,
                        // disable setting tax exempt in prod (should default to true)
                        ...(getStage() === "prod" ? {} : { isTaxExempt }),
                        // if no bcliId is provided, we need to pass bidItemId and revisionId to create a new bcli
                        ...(!id && { bidItemId: bidItem.id, revisionId: revisionId ?? newRevisionId }),
                      },
                    },
                    refetchQueries: ["BidPackageDetailPage"],
                  });
                  closeRightPane();
                }}
              />
            )}
          </Observer>
        </div>
      </div>
    </div>
  );
}

type FormValues = Pick<
  SaveBidContractLineItemInput,
  "id" | "totalCostInCents" | "isTaxExempt" | "bidItemId" | "revisionId"
>;

const formConfig: ObjectConfig<FormValues> = {
  id: { type: "value" },
  totalCostInCents: { type: "value" },
  isTaxExempt: { type: "value" },
};

function HistoryTab() {
  return <div>History</div>;
}
