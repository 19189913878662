import { Only } from "@homebound/beam";
import { useUploaderContext } from "src/contexts/UploaderContext";
import {
  Asset,
  AssetInput,
  DocumentEditorDetailFragment,
  DocumentType,
  SaveDocumentInput,
  useCreateAssetMutation,
  useCreateAssetUploadUrlMutation,
  useMarkDocumentAsUploadedMutation,
  useSaveDocumentsMutation,
} from "src/generated/graphql-types";
import { FileUploader, FileUploaderProps, FileUploaderXss, FileWithDocumentId } from "./FileUploader";

// Define only the required fields for creating the asset without being attached to a document/projectId
export type FileAsset = Pick<Asset, "id" | "attachmentUrl" | "contentType" | "createdAt" | "downloadUrl" | "version">;

export type DocumentUploaderV2Props<X> = Omit<
  FileUploaderProps<X>,
  "getUploadUrl" | "allowedFileTypes" | "onFinish" | "file"
> & {
  documentType: DocumentType;
  file: DocumentEditorDetailFragment | undefined;
  onFinish: (file: DocumentEditorDetailFragment | Asset["id"] | undefined, asset?: FileAsset) => void;
  projectId?: string;
  onUploadStart?: () => void;
};

export type FileWithAsset = FileWithDocumentId & { asset?: FileAsset };

/**
 * Document-specific file uploader, which handles uploading and marking a document as uploaded.
 *
 * When uploading multiple documents, getUploadUrl and onFinish are called once for each file uploaded.
 *
 * If no project (ie parent) id is passed for a document (eg, bill ocr), then we create an asset instead and expect a later mutation to backfill that asset into a document.
 */
export function DocumentUploaderV2<X extends Only<FileUploaderXss, X>>(props: DocumentUploaderV2Props<X>) {
  const { documentType, projectId, onFinish, ...otherProps } = props;
  const { setFiles } = useUploaderContext();

  const [saveDocuments] = useSaveDocumentsMutation();
  const [createAsset] = useCreateAssetMutation();
  const [createAssetUploadUrl] = useCreateAssetUploadUrlMutation();
  const [markDocumentAsUploaded] = useMarkDocumentAsUploadedMutation();

  return (
    <FileUploader
      {...otherProps}
      allowedFileTypes={["application/pdf"]}
      getUploadUrl={async (file: FileWithAsset) => {
        const contentType = "application/pdf";
        props.onUploadStart?.();
        const { data: assetUploadUrlData } = await createAssetUploadUrl({ variables: { input: { contentType } } });
        const assetUrl = assetUploadUrlData?.createAssetUploadUrl!;

        // If we have a projectId, we're uploading a document to a project
        if (projectId) {
          const input: SaveDocumentInput = {
            parentId: projectId,
            documentType,
            name: file.name,
            sizeInBytes: file.size,
            asset: {
              contentType: contentType,
              fileName: file.name,
              s3Key: assetUrl.s3Key,
              sizeInBytes: file.size,
            },
          };
          const { data: documentData } = await saveDocuments({ variables: { input } });
          const { documents } = documentData?.saveDocuments!;
          file.documentId = documents.first?.id;
          setFiles({ [documents.first!.id]: { name: file.name, progress: 0 } });
        } else {
          // If we don't have a projectId, we're uploading the asset that will be attached to the document later when creating the bill
          const input: AssetInput = {
            contentType: contentType,
            fileName: file.name,
            s3Key: assetUrl.s3Key,
            sizeInBytes: file.size,
          };
          const { data: asset } = await createAsset({ variables: { input } });
          file.documentId = asset!.createAsset.asset.id;
          // Save asset to be used when saving the bill so we can attach the asset to the document
          file.asset = asset!.createAsset.asset;
        }
        return assetUrl.uploadUrl;
      }}
      onClear={() => onFinish(undefined)}
      onFinish={async (_result, file: FileWithAsset) => {
        if (projectId) {
          const { data } = await markDocumentAsUploaded({ variables: { documentId: file.documentId! } });
          setFiles({ [file.documentId!]: { name: file.name, progress: 100 } });
          return onFinish(data?.markDocumentAsUploaded.document);
        } else {
          return onFinish(file.documentId, file.asset);
        }
      }}
      onProgress={(progress: number, file: FileWithDocumentId) => {
        if (file.documentId) {
          setFiles({ [file.documentId]: { name: file.name, progress: progress } });
        }
      }}
    />
  );
}
