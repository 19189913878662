import {
  ButtonMenu,
  column,
  Css,
  emptyCell,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  GridTableApi,
  multiFilter,
  numericColumn,
  ScrollableContent,
  simpleHeader,
  singleFilter,
  useGridTableApi,
  usePersistedFilter,
} from "@homebound/beam";
import { format } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { emptyCellDash, priceCell, SearchBox } from "src/components";
import {
  HasDisplayName,
  ScopeTemplateCostCalculatorPage_ComputeReadyPlanCostResultFragment,
  ScopeTemplateCostCalculatorPage_ItemTemplateFragment,
  ScopeTemplateCostCalculatorPage_ItemTemplateItemFragment,
  ScopeTemplateCostCalculatorPage_ProtoProjectItemFragment,
  ScopeTemplateHeader_ItemTemplateFragment,
  useScopeTemplateCostCalculatorPage_ComputeQuery,
  useScopeTemplateCostCalculatorPageQuery,
} from "src/generated/graphql-types";
import { useToggle } from "src/hooks";
import { ScopeTemplateHeader } from "src/routes/developments/templates/components/ScopeTemplateHeader";
import { ItemTemplateItemParams } from "src/routes/routesDef";
import { formatCentsToPrice, queryResult } from "src/utils";

export function ScopeTemplateCostCalculatorPage() {
  const { itemTemplateId } = useParams<ItemTemplateItemParams>();
  const query = useScopeTemplateCostCalculatorPageQuery({ variables: { itemTemplateId } });
  return queryResult(query, ({ itemTemplate }) => (
    <>
      <ScopeTemplateHeader itemTemplate={itemTemplate} />
      <DataView itemTemplate={itemTemplate} />
    </>
  ));
}

function DataView(props: {
  itemTemplate: ScopeTemplateCostCalculatorPage_ItemTemplateFragment & ScopeTemplateHeader_ItemTemplateFragment;
}) {
  const { itemTemplate } = props;
  const api = useGridTableApi<Row>();
  // Should probably not use filters for this, but it works...
  const filterDefs = useMemo(() => createFilters(itemTemplate), [itemTemplate]);
  // Use a unique key because RPO ids can't be shared across pages
  const { filter, setFilter } = usePersistedFilter<any>({
    storageKey: `ScopeTemplateCostCalculatorPage_${itemTemplate.id}`,
    filterDefs,
  });
  const [search, setSearch] = useState<string | undefined>();
  const [debug, toggleDebug] = useToggle(false);

  const readyPlanOptionIds = useMemo(() => {
    return [filter.elevation, filter.palette, filter.spec, ...(filter?.other ? filter.other : [])].compact();
  }, [filter]);

  const options = useMemo(() => {
    return itemTemplate.readyPlan!.options.filter((o) => readyPlanOptionIds.includes(o.id));
  }, [itemTemplate, readyPlanOptionIds]);

  const skip = readyPlanOptionIds.isEmpty;
  const query = useScopeTemplateCostCalculatorPage_ComputeQuery({
    variables: { readyPlanID: itemTemplate.readyPlan!.id, itemTemplateId: itemTemplate.id, readyPlanOptionIds },
    skip,
  });

  // We add a reactive side effect everytime the contingency markup is updated, so the cost can update without any other interaction needed
  useEffect(() => {
    void query.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemTemplate.contingencyMarkupPercentage]);

  const publishedAsString = itemTemplate.activatedAt ? format(itemTemplate.activatedAt, "yyyyMMdd") : "N/A";

  const csvPrefixRows: string[][] = [
    ["Plan", itemTemplate.displayName],
    ["Sellable Sqft", String(query.data?.computeProgramData?.sellableSqft) || "N/A"],
    ["Published", publishedAsString],
    ["Version", itemTemplate.version],
  ];
  const csvFileName =
    [
      // Replace `v30.1` with `v30_1` so that it doesn't mess up the file `.csv` extension
      itemTemplate.displayName.replaceAll(".", "_"),
      publishedAsString,
    ].join("_") + ".csv";

  return (
    <>
      <div css={Css.df.jcsb.$}>
        <Filters filterDefs={filterDefs} filter={filter} onChange={setFilter} />
        <SearchBox onSearch={setSearch} />
        <ButtonMenu
          trigger={{ icon: "verticalDots" }}
          placement="right"
          items={[
            { label: "Download CSV", onClick: () => api.downloadToCsv(csvFileName) },
            { label: "Copy to Clipboard", onClick: () => api.copyToClipboard() },
            { label: "Toggle Debug", onClick: toggleDebug },
          ]}
        />
      </div>
      {!skip &&
        queryResult(query, ({ computeReadyPlanCost }) => (
          <CostView
            api={api}
            result={computeReadyPlanCost}
            search={search}
            options={options}
            debug={debug}
            csvPrefixRows={csvPrefixRows}
          />
        ))}
    </>
  );
}

type CostViewProps = {
  api: GridTableApi<Row>;
  result: ScopeTemplateCostCalculatorPage_ComputeReadyPlanCostResultFragment;
  csvPrefixRows: string[][];
  search: string | undefined;
  options: HasDisplayName[];
  debug: boolean;
};

function CostView({ api, result, search, options, debug, csvPrefixRows }: CostViewProps) {
  const columns = useMemo(() => createColumns(options, debug), [options, debug]);
  const rows = useMemo(() => createRows(options, result), [options, result]);
  return (
    <div css={Css.my2.$}>
      <ScrollableContent>
        <GridTable
          api={api}
          columns={columns}
          rows={rows}
          filter={search}
          csvPrefixRows={csvPrefixRows}
          sorting={{ on: "client" }}
          as="virtual"
        />
      </ScrollableContent>
    </div>
  );
}

type Totals = {
  totalCostInCents: number;
  totalByOptions: Map<string, number>;
};

type Data = ScopeTemplateCostCalculatorPage_ProtoProjectItemFragment & {
  // Each allocate each ITIFragment to a single RPO, to fit our "1 column per RPO" UI
  itisByOption: Map<string, readonly ScopeTemplateCostCalculatorPage_ItemTemplateItemFragment[]>;
};

type Row = { kind: "header" } | { kind: "data"; data: Data } | { kind: "totals"; data: Totals };

function createColumns(options: HasDisplayName[], debug: boolean): GridColumn<Row>[] {
  // These columns are all inferable from `Item Code`, but we add them to the CSV
  // so that the analytics team doesn't have to post-process/join in the data by hand.
  const costDivision = column<Row>({
    header: "Cost Division",
    data: (pi) => pi.item.costCode.division.name,
    totals: emptyCell,
    showIn: "csv",
  });
  const classificationType = column<Row>({
    header: "Classification Type",
    data: (pi) => pi.item.costCode.costClassification.name,
    totals: emptyCell,
    showIn: "csv",
  });
  const classificationSubType = column<Row>({
    header: "Classification Sub Type",
    data: (pi) => pi.item.costCode.costSubClassification?.name,
    totals: emptyCell,
    showIn: "csv",
  });

  const codeColumn = column<Row>({
    header: "Item Code",
    data: (pi) => pi.item.fullCode,
    totals: emptyCell,
    w: "125px",
  });
  const nameColumn = column<Row>({
    header: "Name",
    data: (pi) => pi.name,
    totals: emptyCell,
    w: "400px",
  });
  const locationColumn = column<Row>({
    header: "Location",
    data: (pi) => pi.location.name,
    totals: emptyCell,
    w: "100px",
  });
  const quantityColumn = numericColumn<Row>({
    header: "Quantity",
    data: (pi) => ({
      content: `${pi.quantity ?? ""} ${pi.unitOfMeasure?.name}`,
      value: pi.quantity,
    }),
    totals: emptyCell,
    w: "100px",
  });
  const costSource = column<Row>({
    header: "Cost Source",
    data: (pi) => pi.preferredPotentialBidContractLineItem?.bidContractLineItem?.revision.displayName,
    totals: emptyCell,
    w: "300px",
  });
  const costColumn = numericColumn<Row>({
    header: "Total Cost",
    data: ({ totalCostInCents }) => priceCell({ valueInCents: totalCostInCents }),
    totals: ({ totalCostInCents }) => priceCell({ valueInCents: totalCostInCents }),
    w: "120px",
  });

  const baseColumn = numericColumn<Row>({
    header: "Base",
    data: (pi) => {
      const itis = [pi.itemTemplateItem, ...pi.modifyingTemplateItems]
        .compact()
        .filter((iti) => iti.options.length === 0);
      const valueInCents = itis.sumOrUndefined((iti) => iti.totalCostInCents) || pi.totalCostInCents;
      return {
        value: itis.nonEmpty ? priceCell({ valueInCents }).value : emptyCellDash,
        content: () =>
          itis.isEmpty && pi.item.isContingency ? (
            formatCentsToPrice(pi.totalCostInCents, false, true)
          ) : (
            <ItemTemplateItemsCell itis={itis} debug={debug} />
          ),
      };
    },
    totals: (totals) => {
      const claimedByOptions = [...totals.totalByOptions.values()].sum();
      return priceCell({ valueInCents: totals.totalCostInCents - claimedByOptions });
    },
    w: debug ? "300px" : "120px",
  });

  const optionColumns = options.map((rpo) =>
    numericColumn<Row>({
      id: rpo.id,
      header: rpo.displayName,
      data: (pi) => {
        const valueInCents = pi.itisByOption.get(rpo.id)?.sumOrUndefined((iti) => iti.totalCostInCents);
        return {
          value: valueInCents ? priceCell({ valueInCents }).value : emptyCellDash,
          content: () => <ItemTemplateItemsCell itis={pi.itisByOption.get(rpo.id) ?? []} debug={debug} />,
        };
      },
      totals: (totals) => priceCell({ valueInCents: totals.totalByOptions.get(rpo.id) ?? 0 }),
      w: debug ? "300px" : "120px",
    }),
  );

  return [
    costDivision,
    classificationType,
    classificationSubType,
    codeColumn,
    nameColumn,
    locationColumn,
    quantityColumn,
    costSource,
    costColumn,
    baseColumn,
    ...optionColumns,
  ];
}

function createRows(
  options: HasDisplayName[],
  result: ScopeTemplateCostCalculatorPage_ComputeReadyPlanCostResultFragment,
): GridDataRow<Row>[] {
  // The proto PIs have a series of ITIs that we partition out per option.
  const data = result.protoProjectItems.map((pi) => {
    const itisByOption = options.map((rpo) => {
      // We're on the Coastal rpo:1 column, which ITIs match this cell?
      const itis = [pi.itemTemplateItem, ...pi.modifyingTemplateItems]
        .compact()
        .filter((iti) => iti.options.some((o) => o.id === rpo.id));
      return [rpo.id, itis] as const;
    });
    return { ...pi, itisByOption: new Map(itisByOption) };
  });

  const totals: Totals = {
    totalCostInCents: result.totalCostInCents,
    totalByOptions: new Map(
      options.map((rpo) => {
        return [
          rpo.id,
          data
            .flatMap((d) => d.itisByOption.get(rpo.id) ?? [])
            .map((iti) => iti.totalCostInCents)
            .sum(),
        ] as const;
      }),
    ),
  };

  return [
    { kind: "totals", id: "totals", data: totals },
    simpleHeader,
    // We don't have ids on the proto items, so just use an index
    ...data.map((data, i) => ({ kind: "data" as const, id: String(i), data })),
  ];
}

function createFilters(itemTemplate: ScopeTemplateCostCalculatorPage_ItemTemplateFragment) {
  // Create one singleFilter filter for each required dimension
  const { options: allOptions } = itemTemplate.readyPlan!;
  const elevations = allOptions.filter((o) => o.type.isElevation).sortBy((o) => o.displayName);
  const exteriorPalettes = allOptions.filter((o) => o.type.isExteriorPalette).sortBy((o) => o.displayName);
  const specOptions = allOptions.filter((o) => o.type.isSpecLevel).sortBy((o) => o.displayName);
  const otherOptions = allOptions
    .filter((o) => !o.type.isElevation && !o.type.isSpecLevel)
    .sortBy((o) => o.displayName);

  const getters = {
    getOptionValue: (o: HasDisplayName) => o.id,
    getOptionLabel: (o: HasDisplayName) => o.displayName,
    nothingSelectedText: "-",
  };

  const elevation = singleFilter({ label: "Elevation", options: elevations, ...getters });
  const palette = singleFilter({ label: "Exterior", options: exteriorPalettes, ...getters });
  const spec = singleFilter({ label: "Spec Level", options: specOptions, ...getters });
  const other = multiFilter({ label: "Options", options: otherOptions, ...getters });

  return { elevation, palette, spec, other };
}

type ItemTemplateItemsCellProps = {
  itis: readonly ScopeTemplateCostCalculatorPage_ItemTemplateItemFragment[];
  debug: boolean;
};

function ItemTemplateItemsCell({ itis, debug }: ItemTemplateItemsCellProps) {
  return (
    <div>
      {itis.map((iti) => (
        <div key={iti.id}>
          {formatCentsToPrice(iti.totalCostInCents, false, true)} {debug && iti.id}
        </div>
      ))}
    </div>
  );
}
