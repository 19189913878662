import { gql } from "@apollo/client";
import { createApolloClientWithAuth } from "@homebound/auth-components";
import { Button, Css } from "@homebound/beam";
import { useState } from "react";
import { Stage, getStage } from "src/context";
import { qaServerEnvironment } from "src/utils";
import { PageHeader } from "../layout/PageHeader";

// This is a hackday page to trigger a sync from the explore airtable to the explore database.
// Generally this page shouldn't be an example of integrating multiple services.
// The reason this was put into BP was to have a place where internal auth is already take care of.

export function ExploreSyncPage() {
  const stage = getStage();
  const graphqlBaseUrl = getExploreGraphQLBaseUrl();
  const apolloClient = createApolloClientWithAuth(`${graphqlBaseUrl}/graphql`, {
    serviceName: "internal-frontend",
    serviceVersion: VITE_GIT_COMMIT ?? "local",
  });
  const [syncing, setSyncing] = useState(false);
  const [synced, setSynced] = useState(false);
  const [errors, setErrors] = useState(false);

  async function sync() {
    setSyncing(true);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation importAll {
            importAll {
              emptyResult
            }
          }
        `,
      });
      setSynced(true);
    } catch (e: unknown) {
      setErrors(true);
    }
    setSyncing(false);
  }
  return (
    <>
      <PageHeader title="Explore Sync Page" />

      <div>
        <p css={Css.mb2.$}>
          This page is for triggering a sync from the explore airtable to the explore database. You are currently in the{" "}
          <strong>{stage}</strong> environment.
        </p>

        {synced && <p css={Css.mb2.green500.$}>Synced!</p>}
        {errors && <p css={Css.mb2.red500.$}>Error syncing!</p>}

        <Button label="Sync" onClick={sync} disabled={syncing}></Button>
      </div>
    </>
  );
}

function getExploreGraphQLBaseUrl(stage: Stage = getStage()): string {
  const stageToGraphQL: Record<Stage, string> = {
    local: "http://localhost:4004",
    dev: "https://explore-graphql.dev-homebound.com",
    qa: `https://${qaServerEnvironment().subDomain}.qa-homebound.com`,
    prod: "https://explore-graphql.homebound.com",
  };
  return stageToGraphQL[stage];
}
