import {
  actionColumn,
  Button,
  Checkbox,
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  HasIdAndName,
  NumberField,
  NumberFieldProps,
  numericColumn,
  OpenInDrawerOpts,
  Palette,
  SelectField,
  Tooltip,
  useComputed,
  useSuperDrawer,
} from "@homebound/beam";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Percentage, Price, priceCell } from "src/components";
import { BidItemSelectField } from "src/components/autoPopulateSelects/BidItemSelectField";
import { GlobalPlanTaskSelectField } from "src/components/autoPopulateSelects/GlobalPlanTaskSelectField";
import { ProductSelectField } from "src/components/autoPopulateSelects/ProductSelectField";
import { TradePartnerSelectField } from "src/components/autoPopulateSelects/TradePartnerSelectField";
import {
  ChangeEventLineItemsTabItemFragment,
  ChangeEventLineItemsTabItemTemplateItemFragment,
  ChangeEventLineItemsTabProjectItemFragment,
  ChangeEventLineItemType,
  CostType,
  DeleteEntitiesInput,
  SaveChangeEventLineItemInput,
  SpecsAndSelections_UnitOfMeasureFragment,
} from "src/generated/graphql-types";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { ChangeEventLineItemSuperDrawer } from "src/routes/projects/change-events/ChangeEventLineItemSuperDrawer";
import { ChangeEventLineItemStore } from "src/routes/projects/change-events/models/ChangeEventLineItemStore";
import { ObservableChangeEventLineItem } from "src/routes/projects/change-events/models/ObservableChangeEventLineItem";
import { ObservableChangeEventLineItemInlineAdd } from "src/routes/projects/change-events/models/ObservableChangeEventLineItemInlineAdd";
import { ProjectParams } from "src/routes/routesDef";
import { formatCentsToPrice, isDefined, safeEntries, sum } from "src/utils";
import { itiOptionsDisplayValue } from "../selections/sharedColumns";
import {
  ChangeEventLineItemTradePartnerSelectField,
  tradeNameWithContractVersion,
} from "./components/ChangeEventLineItemTradePartnerSelectField";

export enum CostSourceType {
  MANUAL = "Manual",
  DEV_CONTRACT = "Dev contract",
}

type ChangeEventLineItemsTableRouteParams = ProjectParams & {
  changeEventId: string;
};

export type ChangeEventLintItemsTableProps = {
  items: ChangeEventLineItemsTabItemFragment[];
  locations: HasIdAndName[];
  readOnly?: boolean;
  onCreate: (input: SaveChangeEventLineItemInput) => void;
  onDelete: (input: DeleteEntitiesInput) => void;
  pinnedCelis: string[];
  projectItemsList: ChangeEventLineItemsTabProjectItemFragment[];
  store: ChangeEventLineItemStore;
  unitsOfMeasure: SpecsAndSelections_UnitOfMeasureFragment[];
  search: string;
  showPlanSource?: boolean;
  enableProductConfigPlan: boolean;
};

export function ChangeEventLineItemsTable(props: ChangeEventLintItemsTableProps) {
  const {
    items,
    locations,
    readOnly = false,
    onCreate,
    onDelete,
    pinnedCelis,
    projectItemsList,
    store,
    unitsOfMeasure,
    search,
    enableProductConfigPlan,
    showPlanSource = false,
  } = props;

  const params = useParams<ChangeEventLineItemsTableRouteParams>();

  const { openInDrawer, closeDrawer } = useSuperDrawer();

  const deleteCeli = useCallback(
    async (id: string) => {
      await onDelete({ id: [id] });
      closeDrawer();
    },
    [closeDrawer, onDelete],
  );

  const columns = useMemo(
    () => createColumns(locations, readOnly, items, unitsOfMeasure, openInDrawer, deleteCeli, enableProductConfigPlan),
    [locations, readOnly, items, unitsOfMeasure, openInDrawer, deleteCeli, enableProductConfigPlan],
  );

  const rows = useComputed(
    () =>
      mapToRows({
        store,
        params,
        projectItemsList,
        onCreate,
        readOnly,
        pinnedCelis,
        showPlanSource,
        enableProductConfigPlan,
      }),
    [store, params, projectItemsList, onCreate, readOnly, pinnedCelis, showPlanSource, enableProductConfigPlan],
  );

  return (
    <GridTable
      as="virtual"
      columns={columns}
      rows={rows}
      filter={search}
      rowStyles={rowStyles}
      sorting={{ on: "client" }}
      stickyHeader
      // Use vAlign:top because of our stacked current/diff/total columns
      style={{ rowHeight: "flexible", vAlign: "top", bordered: true, allWhite: true }}
    />
  );
}

// Draw lines between the groups of columns
const between = [3, 7, 12];
const cellBorderStyles = Css.addIn(
  between.map((i) => `& > div:nth-of-type(${i})`).join(","),
  Css.br.add("borderRightColor", Palette.Gray400).$,
).$;
const rowStyles = {
  header: { rowCss: cellBorderStyles },
  lineItem: { rowCss: cellBorderStyles },
  inlineAddCeli: { rowCss: cellBorderStyles },
};

function createColumns(
  locations: HasIdAndName[],
  readOnly: boolean,
  items: ChangeEventLineItemsTabItemFragment[],
  unitsOfMeasure: SpecsAndSelections_UnitOfMeasureFragment[],
  openInDrawer: (opts: OpenInDrawerOpts) => void,
  deleteCeli: (id: string) => void,
  enableProductConfigPlan: boolean,
): GridColumn<ChangeEventLineItemRow>[] {
  const checkboxColumn = actionColumn<ChangeEventLineItemRow>({
    totals: () => ({ content: () => "Totals", colspan: 2, alignment: "left" }),
    header: (row) => (
      <Checkbox label="Select" checkboxOnly selected={row.selected} onChange={() => row.toggleSelect()} />
    ),
    lineItem: (row) => (
      <Checkbox label="Select" checkboxOnly selected={row.selected} onChange={() => row.toggleSelect()} />
    ),
    planItem: emptyCell,
    inlineAddCeli: (row) => {
      if (row.initialState) {
        return {
          alignment: "left",
          content: <Button label="+" onClick={() => row.updateInitialState(false)} variant="text" />,
        };
      }
      return emptyCell;
    },
    clientSideSort: false,
    w: "48px",
    sticky: "left",
  });

  const nameColumn = column<ChangeEventLineItemRow>({
    totals: emptyCell,
    header: "Project Line Item",
    lineItem: (row) => {
      const name = `${row.item.fullCode} ${row.name}`;
      return {
        content: name,
        onClick: () =>
          openInDrawer({
            content: (
              <ChangeEventLineItemSuperDrawer
                changeEventLineItem={row}
                homeownerSelectionId={row.proposedSelection?.id}
                onDelete={deleteCeli}
                readOnly={readOnly}
                title={`${row.item.fullCode} ${row.name}`}
              />
            ),
          }),
      };
    },
    planItem: (row) => {
      const name = itiOptionsDisplayValue(
        // Either the celiIti or piIti will be set
        (row.celiIti ?? row.piIti)!.options,
      );
      const version =
        row.celiIti && row.piIti && row.celiIti.first.displayVersion !== row.piIti.first.displayVersion
          ? `@ ${row.piIti.first.displayVersion} => ${row.celiIti.first.displayVersion}`
          : `@ ${(row.celiIti ?? row.piIti)!.first.displayVersion}`;
      const qualifier =
        row.celiIti && row.piIti && row.celiIti.first.displayVersion === row.piIti.first.displayVersion
          ? "Unchanged"
          : row.celiIti && !row.piIti
            ? "Added"
            : row.piIti && !row.celiIti
              ? "Removed"
              : "Updated";
      const display = `${name} ${version} (${qualifier})`;

      return {
        value: () => display,
        sort: () => display,
        content: () => (
          <>
            <span css={Css.if(!row.celiIti).tdlt.$}>
              {name} {version}
            </span>{" "}
            ({qualifier})
          </>
        ),
      };
    },
    inlineAddCeli: (row) => {
      return {
        alignment: "left",
        content: () =>
          row.initialState ? (
            <Button label="Add Line Item" onClick={() => row.updateInitialState(false)} variant="text" />
          ) : (
            <SelectField
              autoFocus
              {...{ onKeyDown: preventTabDefault }}
              getOptionLabel={(o) => `${o.fullCode} ${o.name}`}
              getOptionValue={(o) => o.id}
              label="item"
              onSelect={(val) => row.updateItemId(val)}
              options={items}
              readOnly={row.itemId}
              value={row.itemId}
            />
          ),
        // Default table-sort is by Project Line Item so give this column a value so sorting groups are: [old items] [pinned new items] [add new item]
        // Otherwise "Add Line Item" was sorting into the middle of the table
        value: "z",
      };
    },
    w: "240px",
    sticky: "left",
  });

  const locationColumn = column<ChangeEventLineItemRow>({
    totals: emptyCell,
    header: "Location",
    lineItem: (row) => ({
      // Assembly location displayLocationPath can get really long, so truncate
      content: () => (
        <Tooltip title={row.locationName}>
          <span css={Css.lineClamp6.$}>{row.locationName}</span>
        </Tooltip>
      ),
      value: row.locationName,
    }),
    planItem: emptyCell,
    inlineAddCeli: (row) => {
      if (!row.initialState && row.itemId) {
        return {
          content: () => (
            <SelectField
              autoFocus
              {...{ onKeyDown: preventTabDefault }}
              label="Location"
              onSelect={(val) => row.updateLocationId(val)}
              options={locations}
              readOnly={row.locationId}
              value={row.locationId}
            />
          ),
          value: "",
        };
      }
      return emptyCell;
    },
    w: "100px",
  });

  const costTypeColumn = column<ChangeEventLineItemRow>({
    totals: emptyCell,
    header: "Cost Type",
    lineItem: (row) => {
      const costTypes = safeEntries(CostType);
      const costType = costTypes.find(([, code]) => code === row.costType);
      if (costType) {
        return costType[0];
      }
    },
    planItem: emptyCell,
    inlineAddCeli: (row) => {
      if (!row.initialState && row.locationId) {
        return {
          content: () => (
            <SelectField
              autoFocus
              {...{ onKeyDown: preventTabDefault }}
              getOptionLabel={([name]) => name}
              getOptionValue={([_, code]) => code}
              label="costType"
              onSelect={(val) => val && row.updateCostType(val)}
              options={safeEntries(CostType)}
              readOnly={row.costType}
              value={row.costType}
            />
          ),
          value: "",
        };
      }
      return emptyCell;
    },
    w: "120px",
  });

  const itemTypeColumn = column<ChangeEventLineItemRow>({
    totals: emptyCell,
    header: "Type",
    lineItem: (row) => typeToName[row.type],
    planItem: emptyCell,
    inlineAddCeli: (row) => {
      if (!row.initialState && row.costType) {
        return {
          content: () => (
            <SelectField
              autoFocus
              {...{ onKeyDown: preventTabDefault }}
              disabledOptions={!row.isMatch ? [ChangeEventLineItemType.Modify] : []}
              getOptionLabel={([name]) => typeToName[ChangeEventLineItemType[name]]}
              getOptionValue={([_, type]) => type}
              label="type"
              readOnly={row.type && row.type === ChangeEventLineItemType.Add}
              onSelect={(val) => val && row.updateType(val)}
              options={safeEntries(ChangeEventLineItemType).filter(
                ([_, code]) => code !== ChangeEventLineItemType.Cutoff,
              )}
              value={row.type}
            />
          ),
          value: "",
        };
      }
      return emptyCell;
    },
    w: "80px",
  });

  const uomColumn = column<ChangeEventLineItemRow>({
    totals: () => emptyCell,
    header: "UOM",
    lineItem: (row) => row.unitOfMeasure?.abbreviation,
    planItem: emptyCell,
    inlineAddCeli: (row) => {
      if (!row.initialState && row.type && row.type === ChangeEventLineItemType["Add"]) {
        return {
          content: () => (
            <SelectField
              autoFocus
              {...{ onKeyDown: preventTabDefault }}
              getOptionLabel={(uom) => uom.abbreviation}
              getOptionValue={(uom) => uom.id}
              label="unitOfMeasure"
              onSelect={(val) => row.updateUnitOfMeasureId(val)}
              options={unitsOfMeasure}
              value={row.unitOfMeasureId}
            />
          ),
        };
      }
      return emptyCell;
    },
    clientSideSort: false,
    w: "85px",
  });

  const taskColumn = column<ChangeEventLineItemRow>({
    totals: emptyCell,
    header: "Task",
    lineItem: (row) => {
      return {
        value: row.originalTask?.name,
        content: () => {
          return (
            row.costType === CostType.Materials && (
              <ShowOldNew>
                <div>{row.originalTask?.name ?? "-"}</div>
                <GlobalPlanTaskSelectField
                  label="Proposed Task"
                  value={row.proposedTask?.id}
                  onSelect={(_, task) => row.updateProposedTask(task)}
                  nothingSelectedText="Please select a task"
                  readOnly={readOnly}
                />
              </ShowOldNew>
            )
          );
        },
      };
    },
    planItem: emptyCell,
    inlineAddCeli: emptyCell,
    w: "120px",
  });

  const bidItemColumn = column<ChangeEventLineItemRow>({
    totals: emptyCell,
    header: "Bid Item",
    lineItem: (row) => {
      return {
        value: row.originalBidItem?.displayName,
        content: () => {
          return (
            <ShowOldNew>
              <div>{row.originalBidItem?.displayName ?? "-"}</div>
              <BidItemSelectField
                label="Proposed Bid Item"
                readOnly={readOnly}
                filter={{ projectItemId: row.projectItemId }}
                // Don't show the original BI in the list
                filterFn={(bi) => bi.id !== row.originalBidItem?.id}
                // If the CELI.proposedBidItem is unset, show the "proposed" as the current value
                unsetLabel={row.originalBidItem?.displayName ?? "-"}
                value={row.proposedBidItem}
                onSelect={(_, bidItem) => row.updateProposedBidItem(bidItem)}
              />
            </ShowOldNew>
          );
        },
      };
    },
    planItem: emptyCell,
    inlineAddCeli: emptyCell,
    w: "120px",
  });

  const selectionColumn = column<ChangeEventLineItemRow>({
    totals: emptyCell,
    // This will sometimes be a selection-based product, sometimes a selection-less product,
    // but it will change per-CELI, so just make the header "Product"
    header: "Product",
    lineItem: (row) => {
      // Show the selection-less product
      if (!row.isSelection) {
        return {
          value: row.originalProduct?.name,
          content: () => {
            return (
              <ShowOldNew>
                <div>{row.originalProduct?.name ?? "-"}</div>
                <ProductSelectField
                  label="Proposed Product"
                  filter={{
                    // If we're using a new BI, match that, otherwise fallback to the original BI
                    bidItems: [row.proposedBidItemId ?? row.originalBidItem?.id].compact(),
                    // ...pretty sure we should be sending in market for the per-market availability lookup
                  }}
                  filterFn={(p) => p.id !== row.originalProduct?.id}
                  value={row.proposedProduct}
                  onSelect={(product) => row.updateProposedProduct(product)}
                  // Set the unsetLabel to current, so that "row.proposedProduct === undefined" makes the proposed the current
                  // ...unless we're changing the bid item, which means we'll need a new product, so don't show the OG product
                  unsetLabel={row.proposedBidItemId ? "-" : (row.originalProduct?.name ?? "-")}
                  readOnly={readOnly}
                />
              </ShowOldNew>
            );
          },
        };
      }

      // Otherwise use selections
      if (!row.isSelection || row.type === ChangeEventLineItemType.Add) {
        return "";
      }
      const isCutoff = row.type === ChangeEventLineItemType.Cutoff;
      // cutoff may have a selection chosen
      const currentName = row.originalSelection?.selectedOption
        ? row.originalSelection?.selectedOption?.name
        : isCutoff
          ? "Undecided"
          : "Undecided";
      const proposedName = row.proposedSelection?.selectedOption?.name || "Undecided";
      return {
        value: [currentName, proposedName].join(" "),
        content: () => {
          return (
            <ShowOldNew>
              <Button
                label={currentName}
                onClick={() =>
                  openInDrawer({
                    content: (
                      <ChangeEventLineItemSuperDrawer
                        changeEventLineItem={row}
                        homeownerSelectionId={row.originalSelection?.id}
                        onDelete={deleteCeli}
                        readOnly={readOnly}
                        title={`${row.item.fullCode} ${row.name} - Current Selection`}
                      />
                    ),
                  })
                }
              />
              <Button
                label={proposedName}
                onClick={() =>
                  openInDrawer({
                    content: (
                      <ChangeEventLineItemSuperDrawer
                        changeEventLineItem={row}
                        homeownerSelectionId={row.proposedSelection?.id}
                        onDelete={deleteCeli}
                        readOnly={readOnly}
                        title={`${row.item.fullCode} ${row.name} - Proposed Selection`}
                      />
                    ),
                  })
                }
              />
            </ShowOldNew>
          );
        },
      };
    },
    planItem: emptyCell,
    inlineAddCeli: emptyCell,
    w: "160px",
  });

  const tradeColumn = column<ChangeEventLineItemRow>({
    totals: emptyCell,
    header: "Trade Partner",
    lineItem: (row) => {
      return {
        value: () => row.originalTradePartner?.name,
        content: () => {
          return (
            <ShowOldNew>
              <div>
                {row.originalBidContractLineItem
                  ? tradeNameWithContractVersion(row.originalBidContractLineItem)
                  : row.originalTradePartner
                    ? `${row.originalTradePartner.name} (Not Contracted)`
                    : "-"}
              </div>
              {row.costSource === CostSourceType.DEV_CONTRACT ? (
                <ChangeEventLineItemTradePartnerSelectField row={row} readOnly={readOnly} />
              ) : (
                <TradePartnerSelectField
                  label="Proposed Trade"
                  onSelect={(_, tp) => row.updateProposedTradePartner(tp)}
                  readOnly={readOnly}
                  value={row.tradePartner}
                />
              )}
            </ShowOldNew>
          );
        },
      };
    },
    planItem: emptyCell,
    inlineAddCeli: emptyCell,
    w: "180px",
  });

  const committedColumn = column<ChangeEventLineItemRow>({
    totals: emptyCell,
    header: "Committed",
    lineItem: (row) => priceCell({ valueInCents: row.allCommittedCostInCents }),
    planItem: emptyCell,
    inlineAddCeli: emptyCell,
    w: "120px",
  });

  const quantityColumn = numericColumn<ChangeEventLineItemRow>({
    totals: emptyCell,
    header: "Quantity",
    lineItem: (row) => {
      if (row.unitOfMeasure?.useQuantity === false) {
        return emptyCell;
      }
      return {
        value: () => combineSearch(row.originalQuantity, row.quantity, row.proposedQuantity),
        sortValue: () => row.proposedQuantity,
        content: () => {
          // Cutoffs are just picking a specific product, not changing it's quantity
          const ro = readOnly || row.type === ChangeEventLineItemType.Cutoff;
          return (
            <ShowOldChangeNew>
              {row.originalQuantity}
              <BufferedNumberField
                label="Qty Change"
                readOnly={ro}
                displayDirection
                value={row.quantity ?? undefined}
                onChange={(val) => {
                  row.updateProposedQuantity((row.originalQuantity ?? 0) + (val ?? 0));
                }}
              />
              <BufferedNumberField
                label="Proposed Quantity"
                readOnly={ro}
                value={row.proposedQuantity}
                onChange={(val) => {
                  if (val !== undefined) {
                    row.updateProposedQuantity(val);
                  }
                }}
              />
            </ShowOldChangeNew>
          );
        },
      };
    },
    planItem: (row) => {
      const celiQty = row.celiIti?.quantity;
      const piQty = row.piIti?.quantity;

      const display =
        celiQty === piQty
          ? celiQty
          : row.celiIti && row.piIti
            ? `${piQty ?? "N/A"} => ${celiQty ?? "N/A"}`
            : row.celiIti
              ? celiQty
              : isDefined(piQty)
                ? -piQty
                : undefined;

      return display ?? " ";
    },
    inlineAddCeli: emptyCell,
    w: "100px",
  });

  const unitCostColumn = numericColumn<ChangeEventLineItemRow>({
    totals: emptyCell,
    header: "Unit Cost",
    lineItem: (row) => {
      if (row.unitOfMeasure?.useQuantity === false) {
        return emptyCell;
      }
      return {
        value: () => combineSearch(row.originalUnitCostInCents, row.proposedUnitCostInCents),
        sortValue: () => row.proposedUnitCostInCents,
        content: () => {
          return (
            <ShowOldChangeNew>
              <Price valueInCents={row.originalUnitCostInCents} />
              <Price valueInCents={row.unitCostChangeInCents} displayDirection />
              <BufferedNumberField
                label="Proposed Unit Cost"
                type="cents"
                readOnly={readOnly || row.costSource === CostSourceType.DEV_CONTRACT}
                value={row.proposedUnitCostInCents}
                onChange={(val) => {
                  if (val !== undefined) {
                    row.updateProposedUnitCostInCents(val);
                  }
                }}
              />
            </ShowOldChangeNew>
          );
        },
      };
    },
    planItem: emptyCell,
    inlineAddCeli: emptyCell,
    w: "140px",
  });

  const totalCostColumn = numericColumn<ChangeEventLineItemRow>({
    header: "Total Cost",
    totals: (row) => {
      return {
        content: () => (
          <Price id="totalCostChangeTotal" valueInCents={sumTotals(row, "totalCostInCents")} displayDirection />
        ),
      };
    },
    lineItem: (row) => {
      return {
        value: () => combineSearch(row.originalTotalCostInCents, row.totalCostInCents, row.proposedTotalCostInCents),
        sort: () => row.proposedTotalCostInCents,
        content: () => {
          const ro =
            readOnly ||
            (row.isSelection && row.type === ChangeEventLineItemType.Add) ||
            row.type === ChangeEventLineItemType.Cutoff ||
            row.costSource === CostSourceType.DEV_CONTRACT;
          return (
            <ShowOldChangeNew>
              <Price valueInCents={row.originalTotalCostInCents} />
              <BufferedNumberField
                label="Total Cost Change"
                type="cents"
                displayDirection
                readOnly={ro}
                value={row.totalCostInCents}
                onChange={(val) => row.updateChangeInTotalCostInCents(val)}
              />
              <BufferedNumberField
                label="Proposed Total Cost"
                type="cents"
                readOnly={ro}
                value={row.proposedTotalCostInCents}
                onChange={(val) => {
                  if (val !== undefined) {
                    row.updateProposedTotalCostInCents(val);
                  }
                }}
              />
            </ShowOldChangeNew>
          );
        },
      };
    },
    planItem: (row) => ({
      value: () => row.celiIti?.totalCostInCents ?? row.piIti?.totalCostInCents ?? 0,
      sort: () => row.celiIti?.totalCostInCents ?? row.piIti?.totalCostInCents ?? 0,
      content: () => {
        if (row.celiIti && row.piIti) {
          if (row.celiIti.totalCostInCents !== row.piIti.totalCostInCents) {
            return (
              <>
                <Price valueInCents={row.piIti.totalCostInCents} /> {"=>"}{" "}
                <Price valueInCents={row.celiIti.totalCostInCents} />
              </>
            );
          }
          return <Price valueInCents={row.celiIti.totalCostInCents} />;
        } else if (row.celiIti) {
          return <Price valueInCents={row.celiIti.totalCostInCents} />;
        } else if (row.piIti) {
          return <Price valueInCents={-row.piIti.totalCostInCents} />;
        }
      },
    }),
    inlineAddCeli: emptyCell,
    w: "140px",
  });

  const markupColumn = numericColumn<ChangeEventLineItemRow>({
    totals: emptyCell,
    header: "Markup",
    lineItem: (row) => {
      if (row.isInternal) {
        return emptyCell;
      }
      return {
        value: () => combineSearch(row.originalMarkupPercent, row.proposedMarkupPercent),
        sortValue: () => row.proposedMarkupPercent,
        content: () => {
          return (
            <ShowOldChangeNew>
              <Percentage percent={row.originalMarkupPercent} />
              {/* We don't bother with "change in markup, but use a placeholder. */}
              <div />
              <BufferedNumberField
                label="Proposed Markup"
                type="percent"
                numFractionDigits={2}
                readOnly={readOnly}
                value={row.proposedMarkupPercent}
                disabled={disableBasedOnPotentialOperation(row.fragment.projectItem.project.canEditPrice)}
                errorMsg={row.alertMarkup}
                onChange={(val) => {
                  if (val !== undefined) {
                    row.updateProposedMarkupPercent(val);
                  }
                }}
              />
            </ShowOldChangeNew>
          );
        },
      };
    },
    planItem: emptyCell,
    inlineAddCeli: emptyCell,
    w: "100px",
  });

  const totalPriceColumn = numericColumn<ChangeEventLineItemRow>({
    header: "Total Price",
    totals: (row) => ({
      content: () => (
        <Price id="totalPriceChangeTotal" valueInCents={sumTotals(row, "totalPriceInCents")} displayDirection />
      ),
    }),
    lineItem: (row) => {
      if (row.isInternal) {
        return emptyCell;
      }
      return {
        value: combineSearch(row.originalTotalPriceInCents, row.totalPriceInCents, row.proposedTotalPriceInCents),
        sortValue: () => row.proposedTotalPriceInCents,
        content: () => {
          const disabled = row.fragment.projectItem.project.canEditPrice.allowed === false;
          const ro =
            readOnly ||
            (row.isSelection && row.type === ChangeEventLineItemType.Add) ||
            row.isInternal ||
            row.type === ChangeEventLineItemType.Cutoff ||
            row.costSource === CostSourceType.DEV_CONTRACT;
          return (
            <ShowOldChangeNew>
              <Price valueInCents={row.originalTotalPriceInCents} />
              <BufferedNumberField
                label="Total Price Change"
                type="cents"
                displayDirection
                disabled={disabled}
                readOnly={ro}
                value={row.totalPriceInCents}
                onChange={(val) => row.updateChangeInTotalPriceInCents(val)}
              />
              <BufferedNumberField
                label="Proposed Total Price"
                type="cents"
                disabled={disabled}
                readOnly={ro}
                value={row.proposedTotalPriceInCents}
                onChange={(val) => row.updateProposedTotalPriceInCents(val)}
              />
            </ShowOldChangeNew>
          );
        },
      };
    },
    planItem: emptyCell,
    inlineAddCeli: emptyCell,
    w: "140px",
  });

  const costSourceColumn = column<ChangeEventLineItemRow>({
    totals: emptyCell,
    header: "Cost Source",
    lineItem: (row) => (
      <ShowOldNew>
        {/* We don't show old, but use ShowOldNew to stack the new CostSource down. */}
        <div />
        <SelectField
          label="Cost Source"
          readOnly={readOnly}
          value={row.costSource}
          onSelect={(val) => {
            if (val !== undefined) row.updateCostSource(val);
          }}
          options={[
            { id: CostSourceType.MANUAL, name: CostSourceType.MANUAL },
            { id: CostSourceType.DEV_CONTRACT, name: CostSourceType.DEV_CONTRACT },
          ]}
          disabledOptions={
            !row.developmentId || !(row.originalBidItem || row.proposedBidItemId || row.bidItemTemplateItemId)
              ? [
                  {
                    value: CostSourceType.DEV_CONTRACT,
                    reason: !row.developmentId
                      ? "The project for this change event doesn't belong to any development"
                      : "There must be a selected proposed bid item to enable this option",
                  },
                ]
              : []
          }
        />
      </ShowOldNew>
    ),
    planItem: emptyCell,
    inlineAddCeli: emptyCell,
    clientSideSort: false,
    w: "120px",
  });

  return [
    /* first section */
    collapseColumn<ChangeEventLineItemRow>({ lineItem: emptyCell, w: "32px", sticky: "left" }),
    checkboxColumn,
    nameColumn,
    locationColumn,
    costTypeColumn,
    itemTypeColumn,
    /* second section */
    uomColumn,
    ...(enableProductConfigPlan ? [taskColumn] : [bidItemColumn, selectionColumn]),
    // Put cost source (which is just "manual || dev contract" first, so that the trade column
    // can become either a dumb-list of trades, or
    costSourceColumn,
    tradeColumn,
    committedColumn,
    quantityColumn,
    unitCostColumn,
    totalCostColumn,
    markupColumn,
    totalPriceColumn,
  ];
}

type CeliTotalField =
  | "originalTotalCostInCents"
  | "originalTotalPriceInCents"
  | "proposedTotalCostInCents"
  | "proposedTotalPriceInCents"
  | "totalCostInCents"
  | "totalPriceInCents";

function sumTotals(store: ChangeEventLineItemStore, field: CeliTotalField) {
  return store.items?.map((celi) => celi[field] || 0).reduce(sum, 0);
}

type RowProps = Pick<ChangeEventLintItemsTableProps, "onCreate" | "projectItemsList" | "readOnly" | "store"> & {
  params: ChangeEventLineItemsTableRouteParams;
  pinnedCelis: string[];
  showPlanSource: boolean;
  enableProductConfigPlan: boolean;
};

// Returns the totals rows, header row, existing celi rows and the inline add celi row
function mapToRows(props: RowProps): GridDataRow<ChangeEventLineItemRow>[] {
  const { onCreate, params, pinnedCelis, projectItemsList, readOnly, store, showPlanSource, enableProductConfigPlan } =
    props;
  const { changeEventId } = params;

  const rows: GridDataRow<ChangeEventLineItemRow>[] = [
    // Totals row
    { kind: "totals" as const, id: "totals", data: store },
    // Header row
    { kind: "header", id: "header", data: store },
    // Existing CELI rows - with "pinned" newly created CELIs (resets when re-load)
    ...store.items.map((celi) => ({
      kind: "lineItem" as const,
      id: celi.id,
      data: celi,
      // pin to bottom and ignore sort
      pin: pinnedCelis.includes(celi.id) ? ("last" as const) : undefined,
      children: showPlanSource
        ? celi.itiRows.map((child) => ({
            kind: "planItem" as const,
            id: `${celi.id}-${child.originalItiId}`,
            data: child,
          }))
        : [],
    })),
  ];

  // do not render inline add celi when read only
  if (!readOnly && !enableProductConfigPlan) {
    // Inline add CELI row
    rows.push({
      kind: "inlineAddCeli" as const,
      id: "inlineAddCeli",
      data: new ObservableChangeEventLineItemInlineAdd(
        {
          changeEventId,
          initialState: true,
          projectItemsList,
        },
        onCreate,
      ),
      // pin to bottom and ignore sort
      pin: "last" as const,
    });
  }

  return rows;
}

type PlanItemRowData = {
  piIti: ChangeEventLineItemsTabItemTemplateItemFragment | undefined;
  celiIti: ChangeEventLineItemsTabItemTemplateItemFragment | undefined;
};

type TotalsRow = { kind: "totals"; id: string; data: ChangeEventLineItemStore };
type HeaderRow = { kind: "header"; id: string; data: ChangeEventLineItemStore };
type LineItemRow = { kind: "lineItem"; id: string; data: ObservableChangeEventLineItem };
type PlanItemRow = { kind: "planItem"; id: string; data: PlanItemRowData };
type InlineAddCeliRow = { kind: "inlineAddCeli"; id: string; data: ObservableChangeEventLineItemInlineAdd };
type ChangeEventLineItemRow = TotalsRow | HeaderRow | LineItemRow | PlanItemRow | InlineAddCeliRow;

const typeToName: Record<ChangeEventLineItemType, string> = {
  [ChangeEventLineItemType.Add]: "New",
  [ChangeEventLineItemType.Modify]: "Project",
  [ChangeEventLineItemType.Cutoff]: "Cutoff",
};

/**
 * Wraps Beam's `NumberField`s and delays calling `onChange` until the user blurs out.
 *
 * This prevents triggering auto-save calls on each keystroke, which even if we debounce will cause
 * saving "work in progress values" like "1" when the user means to type "1,0000".
 *
 * Currently, this is our only "auto-save on blur" that is done manually, i.e. in the
 * ObservableChangeEventLineItem constructor. If we move over to form-state's `autoSave`, it
 * will handle this for us, and we can remove this.
 */
function BufferedNumberField(props: NumberFieldProps) {
  const [wip, setWip] = useState(props.value);
  useEffect(() => {
    setWip(props.value);
  }, [props.value]);
  return <NumberField {...props} value={wip} onChange={setWip} onBlur={() => props.onChange(wip)} />;
}

/**
 * - We're using the `autoFocus` prop on each new selectField to have the `ENTER` key bring the next field into focus.
 * - To have `TAB` key do the same we must prevent the browser from moving over the newly rendered field to the next element in the tabIndex order.
 * This is because the newly rendered field has already been brought into focus before the browser has received the tab event.
 * Resulting in tab event to be triggered from the newly rendered field, causing focus to move off from the field and onto the next focusable element.
 * - SelectField already knows to treat TAB as enter (see react-stately), so we need this to have parity with both keys
 * - This is NOT our default pattern!!! This table is unique!!!
 */
const preventTabDefault = (e: React.KeyboardEvent<HTMLInputElement>) => e.key === "Tab" && e.preventDefault();

/** Shows three old, change, new fields stacked on top of each other. */
function ShowOldChangeNew(props: { children: ReactNode[] }) {
  const [old, change, new_] = props.children;
  return (
    <div css={Css.df.fdc.hPx(90).jcsb.$}>
      <div data-testid="current">{old}</div>
      <div data-testid="change">{change}</div>
      <div data-testid="proposed">{new_}</div>
    </div>
  );
}

/** Shows two old, new fields stacked on top of each other. */
function ShowOldNew(props: { children: ReactNode[] }) {
  const [old, new_] = props.children;
  return (
    <div css={Css.df.fdc.hPx(90).jcsb.$}>
      <div data-testid="current">{old}</div>
      <div data-testid="proposed">{new_}</div>
    </div>
  );
}

/** Combine several numbers into a single search string for type-ahead search. */
function combineSearch(...numbers: Array<number | null | undefined>): string {
  return numbers
    .compact()
    .map((n) => formatCentsToPrice(n))
    .join(" ");
}
