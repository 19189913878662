import { BoundSelectField, Css } from "@homebound/beam";
import { FieldState, ObjectState } from "@homebound/form-state";
import { Observer } from "mobx-react-lite";
import { useMemo } from "react";
import {
  ScheduleDraftMode_ScheduleFlagReasonFragment,
  ScheduleFlagReasonDetailFragment,
  ScheduleFlagReasonType,
} from "src/generated/graphql-types";

type ScheduleFlagReasonDetail = ScheduleFlagReasonDetailFragment | ScheduleDraftMode_ScheduleFlagReasonFragment;
// Generic type allows us to use this bound select for various forms
type BoundScheduleFlagReasonSelectFormState<T> = ObjectState<T> & {
  category: FieldState<string | null | undefined>;
  reasonId: FieldState<string | null | undefined>;
};

type BoundScheduleFlagReasonSelectsProps<T> = {
  formState: BoundScheduleFlagReasonSelectFormState<T>;
  scheduleFlagReasons: ScheduleFlagReasonDetail[];
  isDelayFlagPublishStep?: boolean;
};

export function BoundScheduleFlagReasonSelects<T>({
  formState,
  scheduleFlagReasons,
  isDelayFlagPublishStep = false,
}: BoundScheduleFlagReasonSelectsProps<T>) {
  const scheduleFlagReasonTypes = useMemo(
    () =>
      scheduleFlagReasons
        .flatMap((r) => r.scheduleFlagReasonType)
        .uniqueBy((t) => t.code)
        .sortBy((t) => t.name),
    [scheduleFlagReasons],
  );
  const groupedReasons = useMemo(
    () =>
      scheduleFlagReasons
        .sortBy((sfr) => sfr.title)
        .groupBy(({ scheduleFlagReasonType }) => scheduleFlagReasonType.code),
    [scheduleFlagReasons],
  );

  return (
    <Observer>
      {() => (
        <>
          <div css={Css.if(isDelayFlagPublishStep).mr2.$}>
            <BoundSelectField
              field={formState.category}
              label="Delay Category"
              data-testid="delayCategory"
              getOptionLabel={({ name }) => name}
              getOptionValue={({ code }) => code}
              options={scheduleFlagReasonTypes}
              compact
              labelStyle={isDelayFlagPublishStep ? "hidden" : undefined}
            />
          </div>
          <BoundSelectField
            // only enable reasons if we have a category selected
            disabled={!formState.category.value}
            field={formState.reasonId}
            label="Delay Reason"
            getOptionLabel={({ title }) => title}
            getOptionValue={({ id }) => id}
            options={groupedReasons[formState.category.value as ScheduleFlagReasonType] ?? []}
            compact
            labelStyle={isDelayFlagPublishStep ? "hidden" : undefined}
          />
        </>
      )}
    </Observer>
  );
}
