import {
  Button,
  Css,
  IconButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Palette,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import {
  BidPackageDetailFragment,
  BidPackagesPage_AvailableUpdateFragment,
  useAcceptBidPackageUpdatesMutation,
  useBidUpdatesAvailable_BidPackageQuery,
} from "src/generated/graphql-types";
import { pluralize, queryResult } from "src/utils";
import { ReadyPlanSourceSection } from "./BidUpdatesSourceSection";

type UpdatesAvailableButtonProps = {
  bidPackageId: string;
  developmentId: string;
  showText?: boolean;
};

export function UpdatesAvailableButton({
  updates,
  bidPackageId,
  showText = true,
  developmentId,
}: UpdatesAvailableButtonProps & { updates: BidPackageDetailFragment["availableUpdates"] }) {
  const { openModal } = useModal();

  if (updates.isEmpty) {
    return null;
  }

  if (!showText) {
    return (
      <IconButton
        inc={2}
        label="Updates available"
        icon="arrowFromBottom"
        bgColor={Palette.Yellow200}
        tooltip={updates.map((u) => `${u.source?.readyPlan.name} - v${u.source?.version}`).join(", ")}
        onClick={() =>
          openModal({
            content: (
              <UpdatesAvailableModal updates={updates} bidPackageId={bidPackageId} developmentId={developmentId} />
            ),
          })
        }
      />
    );
  }

  return (
    <Button
      variant="caution"
      icon="arrowFromBottom"
      label="Updates available"
      tooltip={updates.map((u) => `${u.source?.readyPlan.name} - v${u.source?.version}`).join(", ")}
      onClick={() =>
        openModal({
          content: (
            <UpdatesAvailableModal updates={updates} bidPackageId={bidPackageId} developmentId={developmentId} />
          ),
        })
      }
    />
  );
}

type UpdatesAvailableModalProps = UpdatesAvailableButtonProps;

export function UpdatesAvailableModal({
  updates,
  bidPackageId,
  developmentId,
  showText,
}: UpdatesAvailableModalProps & { updates: BidPackageDetailFragment["availableUpdates"] }) {
  const query = useBidUpdatesAvailable_BidPackageQuery({ variables: { id: bidPackageId } });

  return queryResult(query, ({ bidPackage }) => (
    <UpdatesAvailableModalView
      updates={bidPackage.availableUpdates}
      bidPackageId={bidPackage.id}
      showText={showText}
      developmentId={developmentId}
    />
  ));
}

function UpdatesAvailableModalView({
  updates,
  bidPackageId,
  developmentId,
}: UpdatesAvailableModalProps & { updates: BidPackagesPage_AvailableUpdateFragment[] }) {
  const [acceptBidPackageUpdates] = useAcceptBidPackageUpdatesMutation();
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();

  if (updates.isEmpty) return null;

  async function onAcceptUpdate() {
    const res = await acceptBidPackageUpdates({
      variables: { input: { bidPackageId, bidPackageIncomingUpdateIds: updates.map((u) => u.id) } },
      refetchQueries: ["BidPackageGroups", "BidPackageDetailPage"],
    });
    if (res.data) {
      triggerNotice({ message: "Updates have been pulled into your Bid Package Draft and highlighted in color." });
    }
    closeModal();
  }
  return (
    <>
      <ModalHeader>Updates Available</ModalHeader>
      <ModalBody>
        <div css={Css.mb3.$}>
          Would you like to pull {pluralize(updates.length, "this update", "these updates")} into a new draft for you to
          review and edit?
        </div>
        <div css={Css.df.fdc.gap2.$}>
          {updates.map((u) => (
            <ReadyPlanSourceSection key={u.id} update={u} developmentId={developmentId} />
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" onClick={closeModal} variant="text" />
        <Button label={`Accept ${pluralize(updates.length, "Update")}`} onClick={onAcceptUpdate} />
      </ModalFooter>
    </>
  );
}
