import {
  BoundTreeSelectField,
  Button,
  Chip,
  Css,
  HasIdAndName,
  IconButton,
  NestedOption,
  useComputed,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { createDraftScheduleUrl } from "src/RouteUrls";
import { FixedFooterLayout } from "src/components/layout/FixedFooterLayout";
import {
  DraftModeAddCatalogTask_GlobalPlanTaskFragment,
  GlobalPlanTag,
  InputMaybe,
  ScheduleDraftMode_PlanTaskFragment,
  useDraftCatalogTasksQuery,
} from "src/generated/graphql-types";
import { ProjectParams } from "src/routes/routesDef";
import { pluralize, queryResult } from "src/utils";
import { useDraftScheduleStore } from "./scheduleDraftStore";

export function AddDraftTasks({
  draftTasks,
}: {
  draftTasks: ScheduleDraftMode_PlanTaskFragment[];
  scheduleParentId: string;
}) {
  const { projectId } = useParams<ProjectParams>();
  const query = useDraftCatalogTasksQuery({ variables: { projectId } });

  return queryResult(query, {
    data: ({ globalPlanTags, globalPlanTasks, project }) => (
      <AddTaskList globalPlanTags={globalPlanTags} globalPlanTasks={globalPlanTasks.entities} draftTasks={draftTasks} />
    ),
  });
}

function AddTaskList({
  globalPlanTags,
  globalPlanTasks,
  draftTasks,
}: {
  globalPlanTags: Pick<GlobalPlanTag, "id" | "name">[];
  globalPlanTasks: DraftModeAddCatalogTask_GlobalPlanTaskFragment[];
  draftTasks: ScheduleDraftMode_PlanTaskFragment[];
}) {
  const { projectId } = useParams<ProjectParams>();
  const history = useHistory();
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);
  const { triggerNotice } = useSnackbar();

  const formState = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: { globalPlanTaskIds: [] },
    },
  });

  const existingTaskGlobalIds = useMemo(() => new Set(draftTasks.map((t) => t.globalPlanTask.id)), [draftTasks]);

  const selectedTasks = useComputed(
    () => globalPlanTasks.filter((t) => formState.globalPlanTaskIds.value?.includes(t.id)).sortByKey("name"),
    [formState.globalPlanTaskIds.value, globalPlanTasks],
  );

  const onSubmit = useCallback(() => {
    if (!formState.globalPlanTaskIds.value) return;

    // Double check a duplicate ID didn't get added via the tree select before committing the change
    const newTasksGlobalIds = formState.globalPlanTaskIds.value.filter((id) => !existingTaskGlobalIds.has(id));
    setDraftTaskChanges(
      newTasksGlobalIds.map((globalPlanTaskId) => ({ globalPlanTaskId, clientId: `WIP::${globalPlanTaskId}` })),
    );
    triggerNotice({ message: "New task group added" });
    history.push(createDraftScheduleUrl(projectId));
  }, [
    formState.globalPlanTaskIds.value,
    setDraftTaskChanges,
    triggerNotice,
    history,
    projectId,
    existingTaskGlobalIds,
  ]);

  const onCancel = useCallback(() => history.push(createDraftScheduleUrl(projectId)), [history, projectId]);

  const treeInput = useMemo(
    () => formatTreeSelectInput(globalPlanTags, globalPlanTasks, existingTaskGlobalIds),
    [globalPlanTags, globalPlanTasks, existingTaskGlobalIds],
  );

  return (
    <FixedFooterLayout
      footer={
        <div css={Css.df.gap2.w100.jcfe.$}>
          <Button onClick={onCancel} label="Cancel" variant="secondary" size="lg" />
          <Observer>
            {() => (
              <Button
                label={`Add ${pluralize(selectedTasks.length, "Task")}`}
                onClick={onSubmit}
                disabled={selectedTasks.isEmpty}
                size="lg"
              />
            )}
          </Observer>
        </div>
      }
    >
      <div css={Css.maxwPx(600).tac.ma.$}>
        <h1 css={Css.xl3Sb.mt6.$}>Add Tasks</h1>
        <h3 css={Css.base.my2.mb3.$}>Search existing tasks to add to this schedule</h3>
        <BoundTreeSelectField
          labelStyle="hidden"
          options={treeInput}
          field={formState.globalPlanTaskIds}
          label="Tasks"
          onSelect={(options) => formState.globalPlanTaskIds.set(options.leaf.values)}
          fullWidth
          defaultCollapsed
          multiline
          chipDisplay="leaf"
        />
        <SelectedTasks selectedTasks={selectedTasks} formState={formState} />
      </div>
    </FixedFooterLayout>
  );
}

function formatTreeSelectInput(
  tags: Pick<GlobalPlanTag, "id" | "name">[],
  tasks: DraftModeAddCatalogTask_GlobalPlanTaskFragment[],
  existingTaskGlobalIds: Set<string>,
): NestedOption<HasIdAndName>[] {
  // Tasks can have multiple or no tags, so we must do a custom grouping here, placing un-tagged tasks at the end of the list
  const tagsById = tags
    .map((tag) => ({ id: tag.id, name: tag.name, children: [] as HasIdAndName[] }))
    .keyBy((tag) => tag.id);
  const untaggedTasks: HasIdAndName[] = [];

  // Filter out tasks that are already added so we don't add duplicates
  const filteredTasks = tasks.filter((t) => !existingTaskGlobalIds.has(t.id));

  filteredTasks.forEach((task) => {
    const taskEntry = { id: task.id, name: task.nameForMarket };

    task.tags.forEach((tag) => {
      tagsById[tag.id].children.push(taskEntry);
    });

    if (task.tags.isEmpty) untaggedTasks.push(taskEntry);
  });

  const sortedTagGroups = Object.values(tagsById)
    .filter((tagGroup) => tagGroup.children.nonEmpty)
    .sortByKey("name");
  const sortedUntaggedTasks = untaggedTasks.sortByKey("name");

  return [...sortedTagGroups, { id: "UNTAGGED", name: "Untagged", children: sortedUntaggedTasks }];
}

type FormInput = {
  globalPlanTaskIds: InputMaybe<Array<string>>;
};

const formConfig: ObjectConfig<FormInput> = {
  globalPlanTaskIds: { type: "value" },
};

function SelectedTasks({
  formState,
  selectedTasks,
}: {
  formState: ObjectState<FormInput>;
  selectedTasks: DraftModeAddCatalogTask_GlobalPlanTaskFragment[];
}) {
  const removeSelected = useCallback(
    (selectedId: string) => {
      formState.globalPlanTaskIds.set(formState.globalPlanTaskIds.value?.filter((taskId) => taskId !== selectedId));
    },
    [formState],
  );

  return (
    <div css={Css.bgGray200.p2.mt2.br8.mhPx(500).tal.$} data-testid="selectedTaskList">
      {selectedTasks.isEmpty ? (
        <div css={Css.df.fdc.aic.jcc.add("minHeight", "inherit").$}>
          <h3>No Tasks Selected</h3>
        </div>
      ) : (
        <div css={Css.gray700.smMd.mb2.$}>
          <Chip text={selectedTasks.length.toString()} compact xss={Css.bgBlue700.white.mr1.$} />
          <span>{pluralize(selectedTasks.length, "Task")} Selected</span>
        </div>
      )}
      {selectedTasks.map((t) => (
        <div key={t.id} css={Css.df.jcsb.aic.p1.bgWhite.mb2.br8.$} data-testid="selectedTask">
          {t.nameForMarket}
          <IconButton icon="x" onClick={() => removeSelected(t.id)} data-testid="removeTask" />
        </div>
      ))}
    </div>
  );
}
