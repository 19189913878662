import {
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  simpleHeader,
  useTestIds,
} from "@homebound/beam";

import { snakeCase } from "change-case";
import { emptyCellDash, priceCell } from "src/components";
import { BillPdf_BillLineItemsFragment, BillPdf_TradeLineItemFragment, BillType } from "src/generated/graphql-types";
import { formatList } from "src/utils";

type BillPdfProps = {
  type: BillType;
  lineItems: BillPdf_BillLineItemsFragment[];
  enableProductConfigPlan: boolean;
};

export function BillPdfTable({ type, lineItems, enableProductConfigPlan }: BillPdfProps) {
  const tid = useTestIds({});
  const tableTitle = type === BillType.Deposit ? "Deposit Items" : "Completed Item Codes";

  return (
    <div css={Css.mt6.$}>
      <div css={Css.df.fdr.jcsb.mb4.$}>
        <div css={Css.lgSb.$} {...tid.tableTitle}>
          {tableTitle}
        </div>
      </div>
      <GridTable
        as="table"
        rows={rows({ type, lineItems, enableProductConfigPlan })}
        columns={columns()}
        style={{ bordered: false, allWhite: true }}
        rowStyles={{ header: { cellCss: Css.gray600.$ }, total: { cellCss: Css.smSb.mt2.$ } }}
      />
    </div>
  );
}

type HeaderRow = { kind: "header" };
type GroupRow = { kind: "group"; data: string | BillPdf_TradeLineItemFragment[] };
type DrawRow = { kind: "draw"; data: { displayName: string; taxableAmountInCents: number } };
type DepositRow = { kind: "deposit"; data: { displayName: string; taxableAmountInCents: number } };
type ItemRow = { kind: "item"; data: BillPdf_BillLineItemsFragment };
type TotalRow = { kind: "total"; data: { valueInCents: number; name: string } };
export type Row = HeaderRow | GroupRow | DrawRow | DepositRow | ItemRow | TotalRow;

function rows({ type, lineItems, enableProductConfigPlan }: BillPdfProps): GridDataRow<Row>[] {
  const [subtotal, tax, total] = [
    lineItems.sum((li) => li.taxableAmountInCents),
    lineItems.sum((li) => li.taxInCents),
    lineItems.sum((li) => li.amountInCents),
  ];
  const totals = [totalsRow({ name: "Total Amount Due", valueInCents: total })];
  if (tax !== 0) {
    totals.unshift(
      totalsRow({ name: "Subtotal", valueInCents: subtotal }),
      totalsRow({ name: "Sales Tax", valueInCents: tax }),
    );
  }

  const groupedItis =
    groupByDeposit(lineItems, type) ??
    groupByDraws(lineItems, enableProductConfigPlan) ??
    groupByProratedLines(lineItems, enableProductConfigPlan) ??
    groupByTemplate(lineItems);

  return [simpleHeader, ...groupedItis, ...totals];
}

function totalsRow(data: { valueInCents: number; name: string }) {
  return { kind: "total" as const, id: snakeCase(data.name), data };
}

function groupByTemplate(lis: BillPdf_BillLineItemsFragment[]): GridDataRow<Row>[] {
  const groupedbyTemplates = lis.groupBy(
    (li) => li.projectItem.itemTemplateItem?.parent.displayName ?? "Non template items",
  );
  return Object.entries(groupedbyTemplates).map(([templateName, clis]) => {
    const [planItems, optionItems] = clis
      .uniqueByKey("id")
      .partition((li) => !li.projectItem.itemTemplateItem || !!li.projectItem.itemTemplateItem.readyPlan);
    return {
      kind: "group" as const,
      id: templateName,
      data: planItems.first?.projectItem.itemTemplateItem ? `${templateName} Base House` : templateName,
      pin: templateName.includes("Base House") ? { at: "first" as const, filter: true } : undefined,
      children: [...planItems, ...optionItems].map((iti) => ({
        kind: "item" as const,
        id: iti.id,
        data: iti,
      })),
    };
  });
}

function groupByProratedLines(
  lis: BillPdf_BillLineItemsFragment[],
  enableProductConfigPlan: boolean,
): GridDataRow<Row>[] | undefined {
  if (!enableProductConfigPlan) return undefined;
  // Group by trade line item
  const groupedItems = lis.groupBy(
    (bli) =>
      bli.commitmentLineItem?.primaryBidContractLineItem?.prorations.map((li) => li.tradeLineItem.id).join("") ?? "",
  );
  return groupedItems.toEntries().map(([tradeId, blis]) => ({
    kind: "group" as const,
    id: tradeId,
    data:
      lis.first?.commitmentLineItem?.primaryBidContractLineItem?.prorations.map((proration) => ({
        ...proration.tradeLineItem,
        totalCostInCents: blis.sum((li) => li.taxableAmountInCents),
      })) ?? [],
    children: blis.map((li) => ({ kind: "item" as const, id: li.id, data: li })),
  }));
}

function groupByDraws(
  lis: BillPdf_BillLineItemsFragment[],
  enableProductConfigPlan: boolean,
): GridDataRow<Row>[] | undefined {
  const hasCommitmentDraw = lis.find((li) => !!li.commitmentDraw);
  if (!enableProductConfigPlan || !hasCommitmentDraw) return undefined;
  // Group by commitment draw
  const groupedItems = lis.groupBy((bli) => bli.commitmentDraw?.id ?? "");
  return groupedItems
    .toEntries()
    .map(([drawId, blis]) => {
      const commitmentDraw = blis.first?.commitmentDraw;
      return commitmentDraw
        ? {
            kind: "draw" as const,
            id: drawId,
            data: {
              displayName: `Draw: ${commitmentDraw.task.name}`,
              taxableAmountInCents: blis.sum((li) => li.taxableAmountInCents),
            },
          }
        : // Display lines not associated with a draw individually
          blis.map((li) => ({ kind: "item" as const, id: li.id, data: li }));
    })
    .flat();
}

function groupByDeposit(lis: BillPdf_BillLineItemsFragment[], type: BillType): GridDataRow<Row>[] | undefined {
  if (type !== BillType.Deposit) return undefined;
  const costChangeInCents = lis.sum((li) => li.commitmentLineItem?.costChangeInCents ?? 0);
  const taxableAmountInCents = lis.sum((li) => li.taxableAmountInCents);
  const depositInPercentage = (taxableAmountInCents / costChangeInCents) * 100;
  return [
    {
      kind: "deposit" as const,
      id: lis.first!.id,
      data: {
        displayName: `${depositInPercentage}% Deposit`,
        taxableAmountInCents,
      },
    },
  ];
}

function columns(): GridColumn<Row>[] {
  return [
    collapseColumn<Row>({ item: emptyCell }),
    column<Row>({
      id: "qty",
      header: "QTY",
      group: (data) => ({
        content: Array.isArray(data) ? formatList(data.map((data) => data.productOffering.displayName)) : data,
        colspan: 3,
      }),
      draw: (data) => data.displayName,
      deposit: (data) => data.displayName,
      item: ({ commitmentLineItem }) =>
        commitmentLineItem?.newQuantity || commitmentLineItem?.quantity || emptyCellDash,
      total: emptyCell,
      w: "200px",
    }),
    column<Row>({
      id: "uom",
      header: "UoM",
      group: emptyCell,
      draw: emptyCell,
      deposit: emptyCell,
      item: ({ projectItem: { itemTemplateItem, unitOfMeasure } }) =>
        itemTemplateItem?.unitOfMeasure.abbreviation.toLowerCase() ?? unitOfMeasure.abbreviation.toLowerCase(), // use pi if no iti
      total: emptyCell,
      w: "100px",
    }),
    column<Row>({
      id: "itemCode",
      header: "Item Code",
      group: emptyCell,
      draw: emptyCell,
      deposit: emptyCell,
      item: ({ projectItem: { itemTemplateItem, item } }) => itemTemplateItem?.item.fullCode ?? item.fullCode,
      total: emptyCell,
      w: "150px",
    }),
    column<Row>({
      id: "description",
      header: "Description",
      group: emptyCell,
      draw: emptyCell,
      deposit: emptyCell,
      item: ({ projectItem: { itemTemplateItem, name } }) => itemTemplateItem?.name ?? name,
      total: ({ name }) => name,
      w: "200px",
    }),
    column<Row>({
      id: "unitCost",
      header: "Unit Cost",
      group: emptyCell,
      draw: emptyCell,
      deposit: emptyCell,
      item: ({ commitmentLineItem }) => {
        const { quantity, newQuantity, newTotalCostInCents, primaryBidContractLineItem } = commitmentLineItem ?? {};
        return commitmentLineItem?.projectItem.unitOfMeasure.useQuantity &&
          !primaryBidContractLineItem?.prorations.nonEmpty
          ? priceCell({ valueInCents: (newTotalCostInCents || 0) / (newQuantity || quantity || 1) })
          : emptyCellDash;
      },
      total: emptyCell,
      w: "140px",
    }),
    column<Row>({
      id: "totalCost",
      header: "Total Cost",
      // Show cost for each trade line item
      // Labor tasks associated with multiple trade line items will have their costs accounted for in each individual trade line
      group: (data) =>
        Array.isArray(data) && data.length === 1 ? priceCell({ valueInCents: data[0].totalCostInCents }) : emptyCell,
      draw: (data) => priceCell({ valueInCents: data.taxableAmountInCents }),
      deposit: (data) => priceCell({ valueInCents: data.taxableAmountInCents }),
      item: ({ taxableAmountInCents, commitmentLineItem }) =>
        commitmentLineItem?.primaryBidContractLineItem?.prorations.nonEmpty
          ? emptyCell
          : priceCell({ valueInCents: taxableAmountInCents }),
      total: ({ valueInCents }) => priceCell({ valueInCents }),
      w: "160px",
    }),
  ];
}
