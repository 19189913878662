import { Css } from "@homebound/beam";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { CoverImage } from "src/components";
import { SideNavAndRouterConfig, SideNavProps, navLinksToRoutes } from "src/components/layout/SideNav";
import { SideNavLayout } from "src/components/layout/SideNavLayout";
import {
  FeatureFlagType,
  LotTypeDetail,
  ProjectFeature,
  ProjectLayoutFragment,
  Stage,
  useProjectLayoutQuery,
} from "src/generated/graphql-types";
import { NotFound } from "src/routes/NotFound";

import {
  createBudgetUrl,
  createChangeEventsUrl,
  createCommitmentsUrl,
  createDraftScheduleUrl,
  createEstimatesUrl,
  createHomeownerContractsUrl,
  createInvoicesUrl,
  createPreConServicesUrl,
  createProjectChangeLogUrl,
  createProjectDashboardUrl,
  createProjectDocumentsUrl,
  createProjectExpensesUrl,
  createProjectHomeownerNotesUrl,
  createProjectJobLogsUrl,
  createProjectLotSummaryUrl,
  createProjectScheduleUrl,
  createProjectSettingsUrl,
  createProjectToDosUrl,
  createSpecsAndSelectionsUrl,
} from "src/RouteUrls";
import { useFeatureFlags } from "src/contexts/FeatureFlags/FeatureFlagContext";
import { ChangeEventPage } from "src/routes/projects/change-events/ChangeEventPage";
import { ChangeEventsPage } from "src/routes/projects/change-events/ChangeEventsPage";
import { CommitmentChangeOrderPage } from "src/routes/projects/commitments/CommitmentChangeOrderPage";
import { ProjectContextProvider } from "src/routes/projects/context/ProjectContext";
import { DashboardPage } from "src/routes/projects/dashboard/DashboardPage";
import { DocumentsPage } from "src/routes/projects/documents/DocumentsPage";
import { EstimatePage } from "src/routes/projects/estimates/EstimatePage";
import { ProjectEstimatesPage } from "src/routes/projects/estimates/ProjectEstimatesPage";
import { ExpensesPage } from "src/routes/projects/expenses/ExpensesPage";
import { HomeownerContractChangeOrderPage } from "src/routes/projects/homeowner-contracts/HomeownerContractChangeOrderPage";
import { HomeownerContractPage } from "src/routes/projects/homeowner-contracts/HomeownerContractPage";
import { HomeownerContractsPage } from "src/routes/projects/homeowner-contracts/HomeownerContractsPage";
import { HomeownerNotesPage } from "src/routes/projects/homeowner-notes/HomeownerNotesPage";
import { ProjectInvoicesPage } from "src/routes/projects/invoices/ProjectInvoicesPage";
import { ProjectItemsPage } from "src/routes/projects/items/ProjectItemsPage";
import { JobLogsPage } from "src/routes/projects/job-logs/JobLogsPage";
import { ProjectSettingsPage } from "src/routes/projects/settings/ProjectSettingsPage";
import { ToDosPage } from "src/routes/projects/to-dos/ToDosPage";
import { createHopProjectUrl } from "src/utils/hopLinks";
import { queryResult } from "src/utils/queryResult";
import { BillPage } from "../bills/BillPage";
import { ProjectParams, dynamicSchedulesPath, projectPaths } from "../routesDef";
import { BudgetPage } from "./budget/BudgetPage";
import { ProjectChangeLogPage } from "./change-log/ProjectChangeLogPage";
import { CommitmentPage } from "./commitments/CommitmentPage";
import { CommitmentsPage } from "./commitments/CommitmentsPage";
import { RedirectToActiveStage } from "./components/RedirectToActiveStage";
import { ScheduleDraftMode } from "./dynamic-schedules/draft-mode/ScheduleDraftMode";
import { MilestoneDetailsPage } from "./dynamic-schedules/milestone-view/MilestoneDetailsPage";
import { TaskDetailsPage } from "./dynamic-schedules/task-details/TaskDetailsPage";
import { TaskMaterialsPage } from "./dynamic-schedules/task-details/TaskMaterialsPage";
import { ProjectFinishSchedulePage } from "./finish-schedule/ProjectFinishSchedulePage";
import { InvoicePage, InvoicePageOld } from "./invoices/InvoicePage";
import InvoicePageV2 from "./invoices/invoice-v2/InvoicePageV2";
import { ProjectLotSummaryPage } from "./lot-summary/ProjectLotSummaryPage";
import { ProjectSchedulePageV2 } from "./schedule-v2/ProjectSchedulePageV2";
import { SpecsAndSelectionsRouteHandler } from "./selections/SpecsAndSelectionsPage";

export function ProjectLayout() {
  const { projectId } = useParams<ProjectParams>();
  const query = useProjectLayoutQuery({ variables: { projectId } });

  return queryResult(query, {
    data: ({ project }) => {
      const {
        lotType,
        features,
        name,
        client,
        buildAddress,
        coverImage,
        status,
        market,
        cohort,
        featureFlags,
        latestActiveStage,
      } = project;

      return (
        <ProjectContextProvider
          key={projectId}
          id={projectId}
          client={client}
          buildAddress={buildAddress}
          name={name}
          features={features}
          latestActiveStage={latestActiveStage?.stage.code ?? Stage.Construction}
          lotType={lotType?.code}
          coverImage={coverImage}
          isSet={true}
          status={status}
          market={market}
          cohort={cohort ?? undefined}
          development={cohort?.development ?? undefined}
          featureFlags={featureFlags.map((f) => f.type.code)}
          {...lotType}
        >
          <ProjectLayoutContent project={project} />
        </ProjectContextProvider>
      );
    },
    /**
     * Due to the default behavior of `queryResult` whenever we were at the project A's schedule page and we changed to another project B's,
     * `queryResult` would show still show project A's schedule page until the query for project B's schedule page completed.
     * This causes `RedirectToActiveStage` to redirect to project A's schedule page instead of project B's.
     *
     * with `showLoading: "always"` we are forcing `queryResult` to always show the loading state until the query for project B's schedule page completes.
     *
     */
    showLoading: "always",
  });
}

type ProjectLayoutContentProps = {
  project: ProjectLayoutFragment;
};

function ProjectLayoutContent({ project }: ProjectLayoutContentProps) {
  const { id, lotType, features } = project;
  const { featureIsEnabled } = useFeatureFlags();

  const sideNavProps: SideNavProps = {
    sideNavConfig: projectNavLinks(lotType, id, project.latestActiveStage?.stage.code ?? Stage.Construction, features),
    top: (
      <div css={Css.pb5.$}>
        <CoverImage />
      </div>
    ),
  };

  return (
    <SideNavLayout sideNavProps={sideNavProps}>
      <Switch>
        <Route path={projectPaths.settings} component={ProjectSettingsPage} />
        <Route path={projectPaths.contractChangeOrder} component={HomeownerContractChangeOrderPage} />
        <Route path={projectPaths.contract} component={HomeownerContractPage} />
        <Route path={projectPaths.contracts} component={HomeownerContractsPage} />
        <Route path={projectPaths.commitmentChangeOrder} component={CommitmentChangeOrderPage} />
        <Route path={projectPaths.bill} component={BillPage} />
        <Route path={projectPaths.commitment} component={CommitmentPage} />
        <Route
          path={projectPaths.invoice}
          component={features.includes(ProjectFeature.InvoiceV2) ? InvoicePageV2 : InvoicePageOld}
        />
        <Route
          path={projectPaths.invoiceOld}
          component={features.includes(ProjectFeature.InvoiceV2) ? InvoicePage : InvoicePageOld}
        />
        <Route path={projectPaths.estimate} component={EstimatePage} />
        {/* In the case the schedule URL does not contain the `stage` path variable return the Redirect component */}
        <Route
          path={projectPaths.scheduleBase}
          exact
          render={() => (
            <RedirectToActiveStage
              showPlanning
              projectId={id}
              createUrl={(stage) => createProjectScheduleUrl(id, stage)}
            />
          )}
        />
        <Route path={projectPaths.items} component={ProjectItemsPage} />
        <Route path={dynamicSchedulesPath.draftMode} component={ScheduleDraftMode} />
        <Route exact path={dynamicSchedulesPath.details} component={TaskDetailsPage} />
        <Route path={dynamicSchedulesPath.materials} component={TaskMaterialsPage} />
        <Route path={dynamicSchedulesPath.milestoneDetails} component={MilestoneDetailsPage} />
        <Redirect exact from={projectPaths.dynamicSchedules} to={dynamicSchedulesPath.draftMode} />
        {/* Legacy /selections route that will be redirected to either /specs-and-selections or /pre-con-services */}
        <Redirect exact from={projectPaths.selectionsBase} to={projectPaths.specsAndSelections} />
        <Redirect exact from={projectPaths.selectionsPreCon} to={projectPaths.preConServices} />
        <Redirect exact from={projectPaths.selectionsPreConDetails} to={projectPaths.preConServicesDetail} />
        <Redirect exact from={projectPaths.selectionsConstruction} to={projectPaths.specsAndSelections} />
        <Redirect exact from={projectPaths.selectionsConstructionDetails} to={projectPaths.specsAndSelectionsDetail} />

        {/* /pre-con-services or /pre-con-services/:projectItemId */}
        <Route
          path={projectPaths.preConServices}
          render={() => <SpecsAndSelectionsRouteHandler stage={Stage.PreConExecution} />}
        />

        <Route /* /lot-summary */ path={projectPaths.lotSummary} component={ProjectLotSummaryPage} />
        <Route /* /finish-schedule */ path={projectPaths.finishSchedule} component={ProjectFinishSchedulePage} />

        {/* /specs-and-selections or /specs-and-selections/:projectItemId  */}
        <Route
          path={projectPaths.specsAndSelections}
          render={() => <SpecsAndSelectionsRouteHandler stage={Stage.Construction} />}
        />
        <Route path={projectPaths.changeEvent} component={ChangeEventPage} />
        <Route path={projectPaths.dashboard} component={DashboardPage} />

        <Route path={projectPaths.expenses} component={ExpensesPage} />

        {/* After all nested routes have been defined and caught by a `<Route /> component above, then add any routes defined via the sideNavConfig */}
        {navLinksToRoutes(sideNavProps.sideNavConfig, featureIsEnabled)}

        <Redirect exact from={projectPaths.project} to={projectPaths.dashboard} />
        {/* Otherwise, not found */}
        <Route component={NotFound} />
      </Switch>
    </SideNavLayout>
  );
}

function projectNavLinks(
  lotType: Pick<LotTypeDetail, "shortClientNoun" | "clientContract" | "clientInvoice">,
  projectId: string,
  stage: Stage,
  features: ProjectFeature[] = [],
): SideNavAndRouterConfig[] {
  return [
    {
      title: "Project",
      items: [
        {
          label: "Dashboard",
          component: DashboardPage,
          path: projectPaths.dashboard,
          href: createProjectDashboardUrl(projectId),
        },
        {
          label: "Schedule",
          component: features.includes(ProjectFeature.ProductConfigPlan) ? ScheduleDraftMode : ProjectSchedulePageV2,
          path: features.includes(ProjectFeature.ProductConfigPlan)
            ? dynamicSchedulesPath.draftMode
            : projectPaths.schedule,
          href: features.includes(ProjectFeature.ProductConfigPlan)
            ? createDraftScheduleUrl(projectId)
            : createProjectScheduleUrl(projectId, stage),
        },
        {
          label: "Job Logs",
          component: JobLogsPage,
          path: projectPaths.jobLogs,
          href: createProjectJobLogsUrl(projectId),
        },
        { label: "To-Dos", component: ToDosPage, path: projectPaths.toDos, href: createProjectToDosUrl(projectId) },
        ...(!features.includes(ProjectFeature.ProductConfigPlan)
          ? [{ label: "Pre-Con Services", path: projectPaths.preConServices, href: createPreConServicesUrl(projectId) }]
          : []),
        {
          label: "Lot Summary ",
          path: projectPaths.lotSummary,
          href: createProjectLotSummaryUrl(projectId),
        },
        {
          label: "Specs & Selections",
          path: projectPaths.specsAndSelections,
          href: createSpecsAndSelectionsUrl(projectId),
        },
        {
          label: "Change Events",
          component: ChangeEventsPage,
          path: projectPaths.changeEvents,
          href: createChangeEventsUrl(projectId),
        },
        {
          label: "Change Log",
          path: projectPaths.changeLog,
          href: createProjectChangeLogUrl(projectId),
          component: ProjectChangeLogPage,
          featureFlag: FeatureFlagType.ChangeRequestMvp,
        },
        {
          label: "Documents",
          component: DocumentsPage,
          path: projectPaths.documents,
          href: createProjectDocumentsUrl(projectId),
        },
        {
          label: `H/O Notes`,
          component: HomeownerNotesPage,
          path: projectPaths.homeownerNotes,
          href: createProjectHomeownerNotesUrl(projectId),
        },
      ],
    },
    {
      title: "Finances",
      items: [
        { label: "Budget", component: BudgetPage, path: projectPaths.budgetBase, href: createBudgetUrl(projectId) },
        {
          label: "Estimates",
          component: ProjectEstimatesPage,
          path: projectPaths.estimates,
          href: createEstimatesUrl(projectId),
        },
        {
          label: lotType.clientContract.includes("Contract") ? "Contracts" : `${lotType.clientContract}s`,
          path: projectPaths.contracts,
          href: createHomeownerContractsUrl(projectId),
        },
        {
          label: `${lotType.clientInvoice}s`,
          component: ProjectInvoicesPage,
          path: projectPaths.invoices,
          href: createInvoicesUrl(projectId),
        },
        {
          label: "Trade Commitments",
          component: CommitmentsPage,
          path: [projectPaths.commitments, projectPaths.bill],
          href: createCommitmentsUrl(projectId),
        },
        {
          label: "Trade Expenses",
          component: ExpensesPage,
          path: [],
          href: createProjectExpensesUrl(projectId),
        },
      ],
    },
    {
      items: [
        { label: "H/O Platform", href: createHopProjectUrl(projectId), isExternal: true },
        { label: "Settings", path: projectPaths.settings, href: createProjectSettingsUrl(projectId) },
      ],
    },
  ];
}
