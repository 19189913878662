import {
  Button,
  Css,
  FormHeading,
  HbLoadingSpinner,
  SelectField,
  SuperDrawerContent,
  SuperDrawerHeader,
  useSuperDrawer,
} from "@homebound/beam";
import { Fragment, useEffect } from "react";
import {
  DesignPackageConfiguratorDocument,
  useDesignPackageRoomAlternatesDrawerQuery,
  useSaveDpRplAlternateLocationsMutation,
} from "src/generated/graphql-types";
import useSetState from "src/hooks/useSetState";
import {
  DesignPackageConfiguratorContext,
  useDesignPackageConfiguratorContext,
} from "../DesignPackageConfiguratorContext";

type DesignPackageRoomAlternatesDrawerProps = { locationId: string };

export function DesignPackageRoomAlternatesDrawer({ locationId }: DesignPackageRoomAlternatesDrawerProps) {
  const { designPackage: dp } = useDesignPackageConfiguratorContext();
  const { data, loading } = useDesignPackageRoomAlternatesDrawerQuery({
    variables: { id: dp.id, rpavId: dp.version.id, locationId },
  });

  const [ppRplToDpRpl, setPpRplToDpRpl] = useSetState<Record<string, string>>({});
  useEffect(() => {
    setPpRplToDpRpl(
      data?.designPackage.readyPlanLocations
        // each ppRpl (Bath103, Bath217) should map to a single dpRpl (Bath, Bath Alt 1, Bath Alt 2)
        .flatMap((dpRpl) => dpRpl.planLocations.map((ppRpl) => [ppRpl.id, dpRpl.id] as const))
        .toObject() ?? {},
    );
  }, [data, setPpRplToDpRpl]);

  const [mutate] = useSaveDpRplAlternateLocationsMutation({
    variables: {
      // Swap/Group [PpRpl, DpRpl] tuples to [DpRpl, [PpRpl#1, PpRpl#2, ...]]
      input: Object.entries(ppRplToDpRpl)
        .groupByObject(
          ([ppRplId, dpRplId]) => dpRplId, // group [ppRplId, dpRplId] by dpRplId
          ([ppRplId]) => ppRplId, // and pull out [ppRplId] to be the value[] for each groupBy
        )
        .map(([dpRplId, ppRplIds]) => ({ id: dpRplId, planLocations: ppRplIds })),
    },
    refetchQueries: [DesignPackageConfiguratorDocument],
    onCompleted: useSuperDrawer().closeDrawer,
  });

  if (loading) return <HbLoadingSpinner />;
  return (
    <SuperDrawerContent>
      <SuperDrawerHeader hideControls title="Alternate Room Assignment" />
      {data?.designPackage.readyPlanLocations.isEmpty && (
        <div css={Css.xlBd.red500.pb8.$}>
          Could not find any rooms/RPLs to reassign for {locationId}. Maybe a resync will fix it?
        </div>
      )}
      {data?.designPackage.readyPlanLocations
        .flatMap((dpRpl) => dpRpl.planLocations)
        .groupByObject((ppRpl) => ppRpl.readyPlan.name)
        .map(([planName, ppRpls]) => (
          <Fragment key={planName}>
            <FormHeading title={planName} xss={Css.xlBd.$ as any} />
            <div /* 2 Col Table */ css={Css.df.fdc.pb2.gap1.$}>
              {ppRpls
                .sortBy((ppRpl) => ppRpl.location.name) // sort Bath103 above Bath105 instead of random
                .map((ppRpl) => (
                  <SelectField
                    key={ppRpl.id}
                    label={ppRpl.location.name}
                    labelStyle="left"
                    onSelect={(altLocationId) => setPpRplToDpRpl({ [ppRpl.id]: altLocationId })}
                    options={data.designPackage.readyPlanLocations}
                    value={ppRplToDpRpl[ppRpl.id]}
                    getOptionValue={(o) => o.id}
                    getOptionLabel={(o) => o.location.name}
                  />
                ))}
            </div>
          </Fragment>
        ))}
      <div css={Css.df.jcfe.$}>
        <Button label="Save" onClick={() => mutate()} />
      </div>
    </SuperDrawerContent>
  );
}

export function useDesignPackageRoomAlternatesDrawer() {
  const { openInDrawer } = useSuperDrawer();
  const ctx = useDesignPackageConfiguratorContext();
  return {
    openRoomDrawer: (locationId: string) => {
      openInDrawer({
        content: (
          <DesignPackageConfiguratorContext.Provider value={ctx}>
            <DesignPackageRoomAlternatesDrawer locationId={locationId} />
          </DesignPackageConfiguratorContext.Provider>
        ),
      });
    },
  };
}
