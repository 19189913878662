import { BoundTextAreaField, Css, ToggleButton, useComputed } from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { differenceInBusinessDays, differenceInDays, isAfter } from "date-fns";
import pick from "lodash/pick";
import { reaction } from "mobx";
import { Observer } from "mobx-react";
import { Fragment, useEffect, useMemo, useState } from "react";
import { formatDate } from "src/components";
import { Card } from "src/components/Card";
import {
  BoundAttachments,
  SaveAttachmentModel,
  attachmentConfig,
} from "src/components/boundAttachments/BoundAttachments";
import {
  InputMaybe,
  SavePlanTaskScheduleFlagInput,
  ScheduleDraftMode_PlanTaskFragment,
  ScheduleDraftMode_ScheduleFlagReasonFragment,
  useScheduleDraftModeScheduleFlagReasonsQuery,
} from "src/generated/graphql-types";
import { PublishDraftScheduleStep } from "src/routes/projects/dynamic-schedules/draft-mode/PublishDraftScheduleStepper";
import { isDefined, numToStringWithSign, pluralize } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { BoundScheduleFlagReasonSelects } from "../components/BoundScheduleFlagReasonSelects";
import { generateClientId } from "./ScheduleDraftModeDelayFlagModal";
import { DraftTasksWithChanges, useDraftScheduleStore } from "./scheduleDraftStore";

type DelayFlagPublishStepProps = {
  draftTasks: ScheduleDraftMode_PlanTaskFragment[];
  updateStepStatus: (currentStepCompleted: boolean, currentStepValue: PublishDraftScheduleStep) => void;
};

type DraftTaskWithVariance = DraftTasksWithChanges & {
  varianceInDays: number;
};

export function DelayFlagPublishStep({ draftTasks, updateStepStatus }: DelayFlagPublishStepProps) {
  const { data } = useScheduleDraftModeScheduleFlagReasonsQuery();
  const { scheduleFlagReasons } = data || { scheduleFlagReasons: [] };

  const userAddedDelayFlags = useDraftScheduleStore((state) => state.userAddedScheduleFlags);
  const getTasksWithDateOrDurationChanges = useDraftScheduleStore((state) => state.getTasksWithDateOrDurationChanges);

  const setRequiredDelayFlags = useDraftScheduleStore((state) => state.setRequiredScheduleFlags);
  const name = useDraftScheduleStore((state) => state.scheduleParentName);

  const tasksWithDateOrDurationChanges = useMemo(
    () => getTasksWithDateOrDurationChanges(draftTasks),
    [draftTasks, getTasksWithDateOrDurationChanges],
  );

  // Earliest "critical path" task that was delayed
  const taskToDelay: DraftTaskWithVariance | undefined = useMemo(
    () => maybeGetTaskToDelay(tasksWithDateOrDurationChanges),
    [tasksWithDateOrDurationChanges],
  );

  const delayDuration = useMemo(() => {
    if (!taskToDelay) return;

    const { draftTask, initialValues } = taskToDelay;

    // Calculate delay if the start date has been pushed back
    if (isDefined(initialValues.initialStartDate) && isAfter(draftTask.startDate, initialValues.initialStartDate)) {
      return differenceInBusinessDays(draftTask.startDate, initialValues.initialStartDate);
    }

    // Calculate delay if the duration in days has been increased
    if (isDefined(initialValues.initialDuration) && draftTask.durationInDays > initialValues.initialDuration) {
      return draftTask.durationInDays - initialValues.initialDuration;
    }
  }, [taskToDelay]);

  const formState = useFormState({
    config: delayFormConfig,
    init: {
      input: taskToDelay,
      map: (taskToDelay) => ({
        // If we don't have an oldTaskStartDate or oldTaskEndDate set it to the current task start and end date (new BE rule requires this field)
        oldTaskStartDate: taskToDelay.initialValues.initialStartDate ?? taskToDelay.draftTask.startDate,
        oldTaskEndDate: taskToDelay.initialValues.initialEndDate ?? taskToDelay.draftTask.endDate,
        newTaskStartDate: taskToDelay.draftTask.startDate,
        newTaskEndDate: taskToDelay.draftTask.endDate,
        durationInDays: delayDuration,
        attachments: [],
        category: null,
      }),
    },
  });

  const selectedReason = useComputed(
    () => getSelectedReason(scheduleFlagReasons, formState.reasonId.value),
    [scheduleFlagReasons, formState.reasonId.value],
  );

  const noActiveAttachments = useComputed(
    () => formState.attachments.value.every(({ asset }) => asset.delete),
    [formState.attachments.value],
  );

  // Conditionally add/remove `required` to the description field. Using `reaction` as formstate was not re-rendering
  useMemo(() => {
    return reaction(
      () => formState.description.value,
      () => {
        const reasonId = formState.reasonId.value;
        if (reasonId && selectedReason?.descriptionIsRequired) {
          formState.description.rules.push(required);
        } else {
          formState.description.rules.splice(0, 1);
        }
      },
      { fireImmediately: true },
    );
  }, [formState, selectedReason?.descriptionIsRequired]);

  // Since attachments don't have a required field, we conditionally add the rules to the reasonId
  useMemo(() => {
    return reaction(
      () => formState.reasonId.value,
      () => {
        formState.reasonId.rules.push(() => {
          const reasonId = formState.reasonId.value;
          if (reasonId && selectedReason?.attachmentIsRequired && noActiveAttachments) {
            return "Photo is required for this reason type";
          } else {
            formState.reasonId.rules.splice(0, 1);
          }
        });
      },
      { fireImmediately: true },
    );
  }, [formState, selectedReason?.attachmentIsRequired, noActiveAttachments]);

  // "required" schedule flags (delayed from critical path)
  const delayedTaskChanges = useMemo(() => {
    const { attachments, category, ...others } = formState.value;
    return taskToDelay
      ? {
          id: taskToDelay.draftTask.id,
          scheduleFlags: [
            {
              clientId: generateClientId(taskToDelay.draftTask.id, formState.reasonId.value),
              attachments: attachments?.map(({ asset }) => ({
                asset: pick(asset, [
                  // Dropping downloadUrl, attachmentUrl and createdAt to get the AssetInput shape
                  "contentType",
                  "fileName",
                  "id",
                  "s3Key",
                  "sizeInBytes",
                  "delete",
                ]),
              })),
              ...others,
            },
          ],
        }
      : null;
  }, [formState.reasonId.value, formState.value, taskToDelay]);

  const stepValid = useComputed(() => !isDefined(taskToDelay) || formState.valid, [taskToDelay, formState.valid]);

  useEffect(() => {
    stepValid && setRequiredDelayFlags([...(delayedTaskChanges ? [delayedTaskChanges] : [])]);
  }, [delayedTaskChanges, setRequiredDelayFlags, stepValid]);

  useEffect(() => {
    updateStepStatus(stepValid, PublishDraftScheduleStep.DelayFlags);
  }, [stepValid, updateStepStatus]);

  return (
    <Observer>
      {() => (
        <div css={Css.maxwPx(1200).ma.tac.mt6.$} data-testid="delayFlagPublishStep">
          <h6 css={Css.base.gray700.mb1.$}>{name}</h6>
          <h1 css={Css.xl3Sb.$}>Add Delay Flag</h1>
          <p css={Css.base.mt2.mb5.$}>
            Review the tasks that have delay flags added to them. <br />
            (These include any manually added delay flags and the first task in critical path that you moved).
          </p>
          {taskToDelay && (
            <DelayFlagPublishForm
              taskToDelay={taskToDelay}
              formState={formState}
              scheduleFlagReasons={scheduleFlagReasons}
              selectedReason={selectedReason}
            />
          )}
          {!taskToDelay && userAddedDelayFlags.isEmpty && (
            <div data-testid="noDelays" css={Css.bgGray200.gray700.br8.p4.my2.$}>
              The changes made do not require any delay flags
            </div>
          )}
          {userAddedDelayFlags.map((flag) => {
            return (
              // adding another unique identifier since we may have delay flags on the same task
              <div key={`${flag.clientId}`}>
                <ZeroDayDelayFlagPublishForm
                  zeroDayDraftTask={flag}
                  key={`${flag.taskName}:${flag.reasonId}`}
                  name={flag.taskName}
                  scheduleFlagReasons={scheduleFlagReasons}
                />
              </div>
            );
          })}
          <AffectedTasks tasksWithDateOrDurationChanges={tasksWithDateOrDurationChanges} />
        </div>
      )}
    </Observer>
  );
}

function AffectedTasks({
  tasksWithDateOrDurationChanges,
}: {
  tasksWithDateOrDurationChanges: DraftTasksWithChanges[];
}) {
  const [showTaskCard, setShowTaskCard] = useState(false);

  return (
    <>
      <ToggleButton
        label={`${tasksWithDateOrDurationChanges.length} ${
          tasksWithDateOrDurationChanges.length === 1 ? "Task was" : "Tasks were"
        } affected by the change above`}
        icon={showTaskCard ? "chevronUp" : "chevronDown"}
        onChange={() => setShowTaskCard(!showTaskCard)}
        selected={showTaskCard}
        disabled={tasksWithDateOrDurationChanges.isEmpty}
      />

      <div
        css={Css.mt1.o100.if(!showTaskCard).o0.add("transition", "opacity 300ms").$}
        data-testid="draftModeAffectedTasks"
      >
        <Card>
          <div css={Css.dg.gtc("repeat(5, 1fr)").rgPx(4).sm.tal.$}>
            <div css={headerCellCss}>Task</div>
            <div css={headerCellCss}>Start Date</div>
            <div css={headerCellCss}>End Date</div>
            <div css={headerCellCss}>Duration</div>
            <div></div>
            {tasksWithDateOrDurationChanges.map(({ draftTask, initialValues }) => (
              <Fragment key={draftTask.id}>
                <div css={taskNameCellCss} data-testid={`${draftTask.id}-name`}>
                  {draftTask.name}
                </div>
                <div css={changeCellCss} data-testid={`${draftTask.id}-start`}>
                  {getDateChangeText(initialValues.initialStartDate, draftTask.startDate) ?? "--"}
                </div>
                <div css={changeCellCss} data-testid={`${draftTask.id}-end`}>
                  {getDateChangeText(initialValues.initialEndDate, draftTask.endDate) ?? "--"}
                </div>
                <div css={changeCellCss} data-testid={`${draftTask.id}-duration`}>
                  {getDurationChangeText(initialValues?.initialDuration, draftTask.durationInDays)}
                </div>
                <div></div>
              </Fragment>
            ))}
          </div>
        </Card>
      </div>
    </>
  );
}

const headerCellCss = Css.smMd.$;
const taskNameCellCss = Css.xs.pr1.$;
const changeCellCss = Css.xs.gray700.$;

function getDateChangeText(initialDate: DateOnly | undefined, currentDate: DateOnly) {
  if (!initialDate) return undefined;
  return `${formatDate(initialDate, "medium")} -> ${formatDate(currentDate, "medium")}`;
}

function getDurationChangeText(initialDuration: number | undefined, currentDuration: number) {
  if (!initialDuration) return "--";
  const diff = currentDuration - initialDuration;
  const formattedNumber = numToStringWithSign(diff);
  return `${formattedNumber} ${pluralize(Math.abs(diff), "day")}`;
}

type DelayFlagPublishFormProps = {
  taskToDelay: DraftTaskWithVariance;
  formState: ObjectState<PlanTaskScheduleFlagFormInput>;
  scheduleFlagReasons: ScheduleDraftMode_ScheduleFlagReasonFragment[];
  selectedReason: ScheduleDraftMode_ScheduleFlagReasonFragment | undefined;
};

function DelayFlagPublishForm({
  taskToDelay,
  formState,
  scheduleFlagReasons,
  selectedReason,
}: DelayFlagPublishFormProps) {
  const { varianceInDays } = taskToDelay;
  const startDateChanged = isDefined(taskToDelay.initialValues.initialStartDate);
  const endDateChanged = isDefined(taskToDelay.initialValues.initialEndDate);
  return (
    <div data-testid="delayFlagForm" css={Css.bgWhite.br8.p4.my2.$}>
      <div css={Css.dg.cgPx(16).rgPx(4).sm.tal.$}>
        <div css={{ ...headerCellCss, ...Css.gc("1 / span 1").$ }}>Task</div>
        <div css={{ ...headerCellCss, ...Css.gc("2 / span 1").$ }}>Change</div>
        <div css={{ ...headerCellCss, ...Css.gc("3 / span 1").$ }}>Delay Category*</div>
        <div css={{ ...headerCellCss, ...Css.gc("4 / span 1").$ }}>Delay Reason*</div>
        <div css={{ ...taskNameCellCss, ...Css.gc("1 / span 1").$ }}>{taskToDelay.draftTask.name}</div>
        <div css={{ ...changeCellCss, ...Css.truncate.$ }}>
          {startDateChanged &&
            `Start ${getDateChangeText(taskToDelay.initialValues.initialStartDate, taskToDelay.draftTask.startDate)}`}
          {endDateChanged && (
            <div>
              {`End ${getDateChangeText(taskToDelay.initialValues.initialEndDate, taskToDelay.draftTask.endDate)}`}
              {` (${numToStringWithSign(varianceInDays)} ${pluralize(varianceInDays, "day")})`}
            </div>
          )}
        </div>
        <BoundScheduleFlagReasonSelects
          formState={formState}
          scheduleFlagReasons={scheduleFlagReasons}
          isDelayFlagPublishStep
        />
        {selectedReason?.descriptionIsRequired && (
          <div css={Css.df.fdc.jcfe.gap1.mt2.$}>
            <div css={headerCellCss}>Description*</div>
            <BoundTextAreaField field={formState.description} labelStyle="hidden" />
          </div>
        )}
        <div></div>
        {selectedReason?.attachmentIsRequired && (
          <div css={Css.df.fdc.mt2.$}>
            <div css={headerCellCss}>Photo*</div>
            <BoundAttachments
              showTitle={false}
              field={formState.attachments}
              title="Photo*"
              uppyUploaderProps={{ dragDropHeight: 96, dragDropWidth: 96, dragDropText: "" }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

type ZeroDayDelayFlagPublishFormProps = {
  zeroDayDraftTask: SavePlanTaskScheduleFlagInput;
  name: string;
  scheduleFlagReasons: ScheduleDraftMode_ScheduleFlagReasonFragment[];
};

function ZeroDayDelayFlagPublishForm({
  zeroDayDraftTask,
  name,
  scheduleFlagReasons,
}: ZeroDayDelayFlagPublishFormProps) {
  const scheduleFlagReason = scheduleFlagReasons.find((sfr) => zeroDayDraftTask.reasonId === sfr.id);
  return (
    <>
      <div data-testid="zeroDaydelayFlagForm" css={Css.bgWhite.br8.p4.my2.$}>
        <div css={Css.dg.gtc("repeat(3, 1fr)").rgPx(4).sm.tal.$}>
          <div css={headerCellCss}>Task</div>
          <div css={headerCellCss}>Change</div>
          <div css={headerCellCss}>Delay Reason</div>
          <div css={taskNameCellCss}>{name}</div>
          <div css={changeCellCss}>{"No change"}</div>
          <div css={taskNameCellCss}>
            {`${scheduleFlagReason?.scheduleFlagReasonType.name}, (${scheduleFlagReason?.title})`}
          </div>
        </div>
      </div>
    </>
  );
}

function getTaskVarianceInDays(task: DraftTasksWithChanges): number {
  const { initialEndDate } = task.initialValues;
  const { endDate } = task.draftTask;
  return differenceInDays(endDate.date, initialEndDate ?? endDate);
}

export function maybeGetTaskToDelay(tasksWithDateOrDurationChanges: DraftTasksWithChanges[]) {
  const delayedTask = tasksWithDateOrDurationChanges.find((planTask) => planTask.draftTask.isCriticalPath);
  if (!delayedTask) return;

  const varianceInDays = getTaskVarianceInDays(delayedTask);
  // if the variance is negative, we don't want to show the delay flag
  if (varianceInDays < 0) return;

  return { ...delayedTask, varianceInDays };
}

export type PlanTaskScheduleFlagFormInput = SavePlanTaskScheduleFlagInput & {
  attachments: InputMaybe<SaveAttachmentModel[]>;
  category: InputMaybe<string>;
};

export const delayFormConfig: ObjectConfig<PlanTaskScheduleFlagFormInput> = {
  id: { type: "value" },
  reasonId: { type: "value", rules: [required] },
  category: { type: "value" },
  description: { type: "value" },
  attachments: {
    type: "list",
    config: attachmentConfig,
  },
  durationInDays: { type: "value" },
  oldTaskEndDate: { type: "value" },
  oldTaskStartDate: { type: "value" },
  newTaskEndDate: { type: "value" },
  newTaskStartDate: { type: "value" },
};

function getSelectedReason(
  selectedReasons: ScheduleDraftMode_ScheduleFlagReasonFragment[],
  reasonId: InputMaybe<string>,
) {
  return selectedReasons.find((reason) => reason.id === reasonId);
}
