import { FetchMoreQueryOptions } from "@apollo/client";
import {
  Css,
  GridDataRow,
  GridTable,
  GridTableApi,
  OffsetAndLimit,
  ScrollableContent,
  column,
  simpleHeader,
} from "@homebound/beam";
import { ObjectConfig, useFormStates } from "@homebound/form-state";
import { isBefore, startOfDay, startOfMonth, startOfToday } from "date-fns";
import { useMemo } from "react";
import {
  DocumentEditorDetailFragment,
  ExpensesTable_ExpenseFragment,
  SaveExpenseAllocationsInput,
  useExpensesTableByProjectIdQuery,
} from "src/generated/graphql-types";
import { ExpenseAllocationRow } from "src/routes/projects/expenses/components/ExpenseAllocationTable";
import { ExpenseCellAllocationOptions } from "src/routes/projects/expenses/components/ExpenseCellAllocationOptions";
import { ExpenseCellDetail } from "src/routes/projects/expenses/components/ExpenseCellDetail";
import { queryResult } from "src/utils";

type ExpensesTableProps = {
  projectId: string;
  unallocatedView: boolean;
  refetchHeaderQuery: VoidFunction;
};

const defaultPageOptions: OffsetAndLimit = { limit: 100, offset: 0 };

export function ExpensesTable({ unallocatedView, projectId, refetchHeaderQuery }: ExpensesTableProps) {
  const query = useExpensesTableByProjectIdQuery({
    variables: {
      projectId,
      onlyAllocated: !unallocatedView,
      onlyUnallocated: unallocatedView,
      page: defaultPageOptions,
    },
  });

  return queryResult(query, ({ expensesPage }) => (
    <ExpensesTableView
      expenses={expensesPage.entities}
      unallocatedView={unallocatedView}
      hasNextPage={expensesPage.pageInfo.hasNextPage}
      fetchMore={query.fetchMore}
      refetchHeaderQuery={refetchHeaderQuery}
    />
  ));
}

type ExpensesTableViewProps = {
  expenses: ExpensesTable_ExpenseFragment[];
  unallocatedView: boolean;
  hasNextPage: boolean;
  fetchMore: (options: FetchMoreQueryOptions<{ page: OffsetAndLimit }>) => void;
  refetchHeaderQuery: VoidFunction;
};

/**
 * The table for the two main section, "Unallocated Expenses" / "Items to Allocate"
 * & "Allocated Expenses" on the Expenses page.
 *
 * Each expense is rendered by `ExpenseRow`, which shows the two "Expense Info Card"
 * & "Project Items to Allocate" side-by-side.
 */
function ExpensesTableView(props: ExpensesTableViewProps) {
  const { expenses, unallocatedView, hasNextPage, fetchMore, refetchHeaderQuery } = props;
  const columns = useCreateColumns(refetchHeaderQuery, unallocatedView);
  const rows = useMemo(() => createRows(expenses), [expenses]);

  return (
    <ScrollableContent virtualized>
      <GridTable
        id="expensesTable"
        columns={columns}
        rows={rows}
        as="virtual"
        stickyHeader
        style={{ rowHeight: "flexible", allWhite: true, bordered: true }}
        infiniteScroll={{
          onEndReached() {
            if (hasNextPage) {
              fetchMore({
                variables: { page: { ...defaultPageOptions, limit: expenses.length + defaultPageOptions.limit } },
              });
            }
          },
        }}
      />
    </ScrollableContent>
  );
}

export type ExpenseTableRow = ExpensesTable_ExpenseFragment & {
  tableApi?: GridTableApi<ExpenseAllocationRow>;
  hasCostCode: boolean;
  canChangeCostCode: boolean;
};

type HeaderRow = { kind: "header" };
type ExpenseRow = { kind: "expense"; data: ExpenseTableRow };
type Row = HeaderRow | ExpenseRow;

function useCreateColumns(refetch: VoidFunction, unallocatedView: boolean) {
  const { getFormState } = useFormStates<ExpensesTableInput, ExpensesTable_ExpenseFragment>({
    config: formConfig,
    map: (expense) => mapToFormState(expense),
    getId: (expense) => expense.id,
  });

  return useMemo(
    () => [
      column<Row>({
        header: {
          content: unallocatedView ? "Item to Allocate" : "Allocated Items",
          css: Css.lgSb.$,
          tooltip: unallocatedView
            ? "These charges need to be associated to the appropriate project items to calculate actual costs on the project budget."
            : "These charges is already associated",
        },
        /** Left Side */
        expense: (row) => {
          const formState = getFormState(row);
          return <ExpenseCellDetail formState={formState} row={row} unallocatedView={unallocatedView} />;
        },
      }),
      column<Row>({
        header: {
          content: "Find & Allocate Line Items",
          css: Css.lgSb.$,
          tooltip:
            "Select the project item(s) the overall charge is associated with or create a change event with the appropriate item(s).",
        },
        /** Right Side */
        expense: (row) => {
          const formState = getFormState(row);
          return <ExpenseCellAllocationOptions formState={formState} row={row} refetchHeaderQuery={refetch} />;
        },
        w: 1.3,
      }),
    ],
    [getFormState, refetch, unallocatedView],
  );
}

function createRows(expenses: ExpensesTable_ExpenseFragment[]): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...expenses.map((expense) => ({
      kind: "expense" as const,
      id: expense.id,
      data: {
        ...expense,
        hasCostCode: !!expense.costCode,
        canChangeCostCode: isBefore(startOfDay(expense.expenseDate), startOfMonth(startOfToday())),
      },
    })),
  ];
}

function mapToFormState(expense: ExpensesTable_ExpenseFragment): ExpensesTableInput {
  // Every potential PI, which _might_ have an existing allocation, gets a row
  const expenseAllocations = expense.potentialProjectItems.map(({ id: projectItemId }) => {
    const allocation = expense.allocations.find((a) => a.projectItem.id === projectItemId);
    return {
      // The table row will always have a projectItemId, that's the real key, and only sometimes an allocation id
      id: allocation?.id,
      amountInCents: allocation?.amountInCents,
      expenseId: expense.id,
      projectItemId,
      commitmentLineItemId: allocation?.commitmentLineItem?.id,
    };
  });
  return {
    expense: {
      ...expense,
      documents: new Map(expense.documents.map((d) => [d.id, d])),
    },
    expenseAllocations,
    edittingLines: false,
    merged: expense.allocations.nonEmpty,
  };
}

export type ExpensesTableInput = SaveExpenseAllocationsInput & {
  expense: Pick<ExpensesTable_ExpenseFragment, "id" | "costCode" | "expenseDate" | "project"> & {
    documents: Map<string, DocumentEditorDetailFragment>;
  };
  edittingLines: boolean;
  merged: boolean;
};

const formConfig: ObjectConfig<ExpensesTableInput> = {
  edittingLines: { type: "value" },
  merged: { type: "value" },
  expense: {
    type: "object",
    config: {
      id: { type: "value" },
      costCode: { type: "value" },
      expenseDate: { type: "value", readOnly: true },
      project: {
        type: "object",
        config: {
          id: { type: "value" },
          lotType: {
            type: "object",
            config: {
              code: { type: "value" },
              clientNoun: { type: "value" },
            },
          },
        },
      },
      documents: { type: "value" },
    },
  },
  expenseAllocations: {
    type: "list",
    config: {
      id: { type: "value" },
      amountInCents: { type: "value" },
      expenseId: { type: "value" },
      projectItemId: { type: "value" },
      commitmentLineItemId: { type: "value" },
    },
  },
};
