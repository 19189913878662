import {
  BoundRadioGroupField,
  BoundSelectField,
  Button,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { BidContractType, BidPackageDetailRequestFragment, SaveBidContractInput } from "src/generated/graphql-types";
import { ObjectConfig, required, useFormState } from "src/utils/formState";

type SelectProrationModalProps = {
  requests: BidPackageDetailRequestFragment[];
  bidPackageName: string;
  onContinue: (tradePartnerId: string, type: BidContractType) => void;
};

export function SelectProrationModal({ bidPackageName, requests, onContinue }: SelectProrationModalProps) {
  const { closeModal } = useModal();
  const tradePartners = requests.map((request) => request.tradePartner);
  const formState = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: { tradePartnerId: tradePartners[0].id },
    },
  });

  return (
    <>
      <ModalHeader>Prorate {bidPackageName} Bid</ModalHeader>
      <ModalBody>
        <div css={Css.df.fdc.gap3.$}>
          <BoundSelectField field={formState.tradePartnerId} label="Select Trade:" options={tradePartners} />

          <BoundRadioGroupField
            field={formState.type}
            label="Select Bid Format"
            options={[
              { label: "Grouped By Material", value: BidContractType.GroupedByMaterial },
              { label: "Plan and Option", value: BidContractType.GroupedByPlanAndOption },
            ]}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={() => closeModal()} />
        <Observer>
          {() => (
            <Button
              label="Continue"
              disabled={!formState.valid}
              onClick={() => onContinue(formState.tradePartnerId.value!, formState.type.value!)}
            />
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormValues = Pick<SaveBidContractInput, "tradePartnerId" | "type">;

const formConfig: ObjectConfig<FormValues> = {
  tradePartnerId: { type: "value", rules: [required] },
  type: { type: "value", rules: [required] },
};
