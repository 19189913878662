import { PresentationFieldProps, SelectField } from "@homebound/beam";
import { ReactNode } from "react";
import { emptyCellDash } from "src/components/gridTableCells";
import {
  CostCodeFilter,
  CostCodeSelect_CostCodeFragment,
  Maybe,
  useCostCodeSelectLazyQuery,
} from "src/generated/graphql-types";

export type CostCodeSelectFieldProps = PresentationFieldProps & {
  label?: string;
  value: Maybe<CostCodeSelect_CostCodeFragment>;
  filter?: CostCodeFilter;
  readOnly?: boolean | ReactNode;
  onSelect: (id: string | undefined, selectedCostCode: CostCodeSelect_CostCodeFragment | undefined) => void;
};

export function CostCodeSelectField(props: CostCodeSelectFieldProps) {
  const { filter, value, onSelect, readOnly, label, ...others } = props;
  const [load, { data }] = useCostCodeSelectLazyQuery({
    variables: { filter: filter ?? {} },
    fetchPolicy: "cache-first",
  });
  return (
    <SelectField
      {...others}
      label={label ?? "Cost Code"}
      readOnly={readOnly}
      unsetLabel={emptyCellDash}
      getOptionValue={(o) => o.id}
      getOptionLabel={(o) => o.displayName}
      onSelect={onSelect}
      options={{ current: value ?? undefined, load, options: data?.costCodes }}
      value={value?.id}
    />
  );
}
