import { TabsWithContent } from "@homebound/beam";
import { useState } from "react";
import { useParams } from "react-router";
import { PageBidPackageDetailFragment, useBidPackageDetailPageQuery } from "src/generated/graphql-types";
import { BillTermsTabView } from "src/routes/bids/components/BillTermsTab";
import { queryResult } from "src/utils";
import { BidPackageParams } from "../routesDef";
import { BidPackageHeader } from "./components/BidPackageHeader";
import { BiddersTab } from "./components/BiddersTab";
import { BidsTab } from "./components/BidsTab";

export function BidPackageDetailPage() {
  const { bidPackageId } = useParams<BidPackageParams>();
  const query = useBidPackageDetailPageQuery({ variables: { id: bidPackageId } });
  return queryResult(query, ({ bidPackage }) => <BidPackageDetailView bidPackage={bidPackage} />);
}

type TabValue = "bids" | "bidders" | "billTerms";

export function BidPackageDetailView({ bidPackage }: { bidPackage: PageBidPackageDetailFragment }) {
  const [selectedTab, setSelectedTab] = useState<TabValue>("bids");
  const tradePartnerRevisions = bidPackage.requests.map((request) => ({
    tradePartner: request.tradePartner,
    revision: bidPackage.latestVersion.latestBidContractRevisions.find(
      (bcr) => bcr.bidContract.tradePartner?.id === request.tradePartner.id,
    ),
  }));

  return (
    <>
      <BidPackageHeader bidPackage={bidPackage} />
      <TabsWithContent
        tabs={[
          { name: "Bids", value: "bids", render: () => <BidsTab bidPackage={bidPackage} /> },
          { name: "Bidders", value: "bidders", render: () => <BiddersTab requests={bidPackage.requests} /> },
          {
            name: "Bill Terms",
            value: "billTerms",
            render: () => (
              <BillTermsTabView
                bidPackageId={bidPackage.id}
                developmentId={bidPackage.developments.first?.id}
                tradePartnerRevisions={tradePartnerRevisions}
              />
            ),
          },
        ]}
        selected={selectedTab}
        onChange={setSelectedTab}
      />
    </>
  );
}
