import { BoundTextField, Button, Css, FormLines } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";
import { baseDownloadUrl } from "src/context";
import { DateOnly, formatWithYear } from "src/utils/dates";
import { openInSelf } from "src/utils/window";
import { BidPackageFormState, PublishBidPackageStepProps } from "../PublishBidPackagePage";

export function UpdateBidInfoStep({ formState }: PublishBidPackageStepProps) {
  return (
    <div css={Css.maxw50.mxa.$}>
      <div css={Css.tac.mb5.$}>
        <div css={Css.xl2Bd.mt4.mb2.$}>Update Bid Information</div>
        <div>
          This update and version notes will be sent as an email to selected trades. The bid will revert to bidding
          open.
        </div>
      </div>
      <div css={Css.df.fdc.gap5.$}>
        <div css={Css.bgWhite.br8.p4.bshBasic.$}>
          <div css={Css.lgBd.mb3.$}>Bid Templates Link</div>
          <Button
            variant="text"
            onClick={() => onDownloadTemplate(formState)}
            data-testid="csvTemplateLink"
            label={`HomeboundBidPackage-${formatWithYear(new DateOnly(new Date())).replaceAll("/", "-")}.xlsx`}
          />
        </div>
        <div css={Css.bgWhite.br8.p4.bshBasic.$}>
          <div css={Css.lgBd.mb4.$}>Plan Room Link</div>
          <BoundTextField field={formState.planRoomLink} labelStyle="hidden" />
        </div>
        <div css={Css.bgWhite.br8.p4.bshBasic.$}>
          <div css={Css.lgBd.mb4.$}>Set the Bid Timeline</div>
          <div css={Css.df.fdc.gap2.w(50).$}>
            <FormLines labelSuffix={{ required: "*" }} labelStyle="left" width="full">
              <BoundBeamDateField field={formState.dueDate} label="Bid Due Date" required />
              <BoundBeamDateField field={formState.estimatedAwardDate} label="Bid Awarded" required />
              <BoundBeamDateField field={formState.estimatedConstructionDate} label="Est. Construction Start" />
            </FormLines>
          </div>
        </div>
        {/* Since we don't have a version history for this page to show on we should hide this for now */}
        {/* <div css={Css.bgWhite.br8.p4.bshBasic.$}>
          <div css={Css.lgBd.mb4.$}>Add a note</div>
          <BoundTextAreaField
            fullWidth
            field={formState.versionNotes}
            labelStyle="hidden"
            placeholder="This note is for internal use only."
          />
        </div> */}
      </div>
    </div>
  );
}

export const onDownloadTemplate = (formState: ObjectState<BidPackageFormState>) => {
  const params = new URLSearchParams({
    type: formState.value.fragment.isUnitBased ? "unitBasedBidPackage" : "planBasedBidPackage",
    bidPackageRequestId: formState.value.fragment.requests.first?.id ?? "",
  });
  openInSelf(`${baseDownloadUrl()}/xlsx?${params.toString()}`);
};
