import {
  Button,
  Chip,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  IconButton,
  Palette,
  RowStyles,
  Tooltip,
  collapseColumn,
  column,
  emptyCell,
  numericColumn,
  useModal,
} from "@homebound/beam";
import xor from "lodash/xor";
import { Price, priceCell } from "src/components";
import { useFullscreenModal } from "src/components/useFullscreenModal";
import {
  BidContractType,
  BidPackageDetailRequestFragment,
  BidPackageStatus,
  BidPackageTakeoffLineItemVersionFragment,
  Maybe,
  NamedFragment,
  PageBidPackageDetailFragment,
} from "src/generated/graphql-types";
import { DropCodeCell } from "src/routes/developments/product-offerings/components/DropCodeCell";
import { AggregateMemberVersionChangeType } from "src/routes/libraries/design-catalog/design-package-configurator/components/DesignUpdatesAvailableButton";
import { fail, groupBy, pluralize } from "src/utils";
import { getOptionsKey } from "./BidsTab";
import { TradeCostData, sumTradeCostData } from "./BidsTab.utils";
import { AddProrationBidModal, EditProrationBidModal } from "./ProrateBidModal";
import { SelectProrationModal } from "./SelectProrationModal";

type PlanBasedBidsTableProps = {
  bidPackage: PageBidPackageDetailFragment;
  searchFilter?: string;
};

export function PlanBasedBidsTable({ bidPackage, searchFilter }: PlanBasedBidsTableProps) {
  const { openModal } = useModal();
  const { openFullscreen } = useFullscreenModal();

  const tradePartnerIdsWithRevisions = bidPackage.latestVersion.latestBidContractRevisions
    .map((bcr) => bcr.bidContract.tradePartner?.id)
    .compact();
  const requestsWithoutContracts = bidPackage.requests.filter(
    (r) => !tradePartnerIdsWithRevisions.includes(r.tradePartner.id),
  );
  const isDraft = bidPackage.status.code === BidPackageStatus.Draft;

  function createProratedBid() {
    openModal({
      content: (
        <SelectProrationModal
          requests={requestsWithoutContracts}
          bidPackageName={bidPackage.name}
          onContinue={(tradePartnerId, type) => {
            const request = requestsWithoutContracts.find((r) => r.tradePartner.id === tradePartnerId);
            if (!request) {
              fail("Unable to find request");
            }
            openFullscreen(
              <AddProrationBidModal
                newBidContractParams={{
                  bidRequestId: request.id,
                  developmentIds: bidPackage.developments.map(({ id }) => id),
                  tradePartnerId,
                  type,
                }}
                scopeLineItems={bidPackage.scopeLines.map((sl) => sl.takeoffLineItemVersion)}
                tradePartnerName={request.tradePartner.name}
              />,
            );
          }}
        />
      ),
    });
  }

  function onEditProratedBid(bidContractRevisionId: string) {
    openFullscreen(
      <EditProrationBidModal
        bidContractRevisionId={bidContractRevisionId}
        scopeLineItems={bidPackage.scopeLines.map((sl) => sl.takeoffLineItemVersion)}
      />,
    );
  }

  const rows = createRows(bidPackage);
  const columns = createColumns(bidPackage, createProratedBid, requestsWithoutContracts, onEditProratedBid, isDraft);
  return <GridTable columns={columns} filter={searchFilter} rows={rows} rowStyles={rowStyles} />;
}

const cellBorderStyles = {
  // add border to split trade partner columns
  ...Css.addIn("& > div:nth-of-type(n + 7)", Css.bl.bcGray200.$).$,
};

const rowStyles: RowStyles<Row> = {
  total: {
    cellCss: Css.py3.$,
  },
  head: { rowCss: cellBorderStyles },
  plan: { rowCss: cellBorderStyles },
  option: { rowCss: cellBorderStyles },
  tliv: { rowCss: cellBorderStyles },
};

function createRows(bidPackage: PageBidPackageDetailFragment): GridDataRow<Row>[] {
  const requests = bidPackage.requests;
  const tradeIds = requests.map((r) => r.tradePartner.id);
  const itemRows = bidPackage.scopeLines.map(({ takeoffLineItemVersion, changeType, previous }) => ({
    kind: "tliv" as const,
    id: takeoffLineItemVersion.id,
    data: {
      item: takeoffLineItemVersion,
      changeType,
      previous,
      // For each materialized scope line, each trade could have bid at most once on it
      costData: requests.keyBy(
        (bpr) => bpr.tradePartner.id,
        (bpr) => {
          const bidContractRevision = bidPackage.latestVersion.latestBidContractRevisions.find(
            (bcr) => bcr.bidContract.tradePartner?.id === bpr.tradePartner.id,
          );
          // if bpr.bidcontract.type is unit based then use the bid contract line item total cost
          if (bidContractRevision?.bidContract?.type?.code === BidContractType.UnitBased) {
            const bcli = bidContractRevision.lineItems.find(
              (li) => li.bidItem?.id === takeoffLineItemVersion.bidItem?.id,
            );
            // this is the unit cost on the BCLI
            return (bcli?.totalCostInCents ?? 0) * (takeoffLineItemVersion.quantity || 1);
          }
          // for prorated bids we would want to use the itemtemplateitem cost in cents
          const revisionBcli = bidContractRevision?.lineItems.find(
            (li) => li.itemTemplateItem?.id === takeoffLineItemVersion.id,
          );
          // These are plan based tli therefore the total cost
          return revisionBcli?.totalCostInCents ?? 0;
        },
      ),
    },
  }));

  // We need to have plan in here to where there are different rows for base plans for each product offering
  const optionGroupBy = groupBy(
    itemRows,
    (i) => i.data.item.readyPlan!.id + (getOptionsKey(i.data.item.options) || "base"),
  );
  const optionRows = Object.keys(optionGroupBy).map((key) => {
    const children = optionGroupBy[key];
    const { item } = children[0].data;
    return {
      kind: "option" as const,
      id: key,
      data: {
        options: item.options,
        costData: children.map((c) => c.data.costData).reduce(sumTradeCostData.bind(null, tradeIds), {}),
      },
      children,
    };
  });

  const planGroupBy = groupBy(optionRows, (o) => o.children[0].data.item.readyPlan!.id);
  const planRows = Object.keys(planGroupBy).map((key, i) => {
    const children = planGroupBy[key];
    return {
      kind: "plan" as const,
      id: key,
      data: {
        plan: children[0].children[0].data.item.readyPlan!,
        costData: children.map((c) => c.data.costData).reduce(sumTradeCostData.bind(null, tradeIds), {}),
      },
      children: children,
    };
  });

  /*
    Not using the header/totals row type because it makes assumptions about placement, which we don't want to make.
    Using pin to prevent the total and head rows from being hidden when searching
  */
  const optionTypesGrouped = bidPackage.scopeLines
    .flatMap((scopeLine) => scopeLine.takeoffLineItemVersion.options)
    .unique()
    .groupBy((o) => o.type.id);

  const optionTypesCount = Object.entries(optionTypesGrouped).map(([, options]) => ({
    optionType: options[0].type.name,
    count: options.length,
  }));
  return [
    {
      kind: "total" as const,
      id: "total",
      data: {
        productOfferingCount: bidPackage.productOfferings.length,
        optionTypesCount,
      },
      pin: "first",
    },
    { kind: "head" as const, id: "head", data: undefined, pin: "last" },
    ...planRows,
  ];
}

type TotalRow = {
  kind: "total";
  data: {
    productOfferingCount: number;
    optionTypesCount: { optionType: string; count: number }[];
  };
};
type HeaderRow = { kind: "head"; data: undefined };
type PlanRow = { kind: "plan"; data: { plan: NamedFragment; costData: TradeCostData } };
type OptionRow = { kind: "option"; data: { options: NamedFragment[]; costData: TradeCostData } };
type TLIVRow = {
  kind: "tliv";
  data: {
    item: BidPackageTakeoffLineItemVersionFragment;
    changeType: Maybe<string>;
    previous: Maybe<BidPackageTakeoffLineItemVersionFragment>;
    costData: TradeCostData;
  };
};

type Row = HeaderRow | TotalRow | PlanRow | OptionRow | TLIVRow;

function createColumns(
  bidPackage: PageBidPackageDetailFragment,
  createProratedBid: () => void,
  requestsWithoutContracts: BidPackageDetailRequestFragment[],
  onEditProratedBid: (bidContractRevisionId: string) => void,
  isDraft: boolean,
): GridColumn<Row>[] {
  return [
    collapseColumn<Row>({
      total: ({ productOfferingCount, optionTypesCount }) => ({
        content: (
          <div css={Css.df.w100.aic.gap2.$}>
            <div css={Css.xlSb.$}>Bid Total</div>
            <Chip text={`${productOfferingCount} ${pluralize(productOfferingCount, "Offering")}`} type="neutral" />
            {optionTypesCount.map(({ optionType, count }) => (
              <Chip key={optionType} text={`${count} ${pluralize(count, optionType)}`} type="neutral" />
            ))}
            {!isDraft && (
              <div css={Css.mla.$}>
                <Button
                  variant="text"
                  label="Add Bid"
                  disabled={requestsWithoutContracts.length === 0 && "All scope has existing bid"}
                  onClick={createProratedBid}
                />
              </div>
            )}
          </div>
        ),
        colspan: 6 + bidPackage.requests.length,
      }),
      tliv: (data) => ({
        content: emptyCell.content,
        css: isDraft ? applyStyling(data) : undefined,
      }),
    }),
    column<Row>({
      total: emptyCell,
      head: "Code",
      plan: emptyCell,
      option: emptyCell,
      tliv: (data) => ({
        content: <DropCodeCell scope={data.item} />,
        css: isDraft ? applyStyling(data, "developmentDrop") : undefined,
      }),
      w: "90px",
    }),
    column<Row>({
      total: emptyCell,
      head: "Name",
      plan: ({ plan }) => ({
        content: plan.name,
        css: Css.smSb.$,
      }),
      option: ({ options }) => options.map((o) => o.name).join(", ") || "Base",
      tliv: (data) => ({
        content: data.item.name,
        css: isDraft ? applyStyling(data, "materialVariant") : undefined,
      }),
    }),
    column<Row>({
      total: emptyCell,
      head: "Material Code",
      plan: emptyCell,
      option: emptyCell,
      tliv: (data) => ({
        content: data.item.bidItem?.parentMaterialVariant?.code,
        css: isDraft ? applyStyling(data, "materialVariant") : undefined,
      }),
      w: "120px",
    }),
    column<Row>({
      total: emptyCell,
      head: "Qty",
      plan: emptyCell,
      option: emptyCell,
      tliv: (data) => ({
        content: data.item.quantity,
        css: isDraft ? applyStyling(data, "quantity") : undefined,
      }),
      w: "53px",
    }),
    column<Row>({
      total: emptyCell,
      head: "UoM",
      plan: emptyCell,
      option: emptyCell,
      tliv: (data) => ({
        content: data.item.unitOfMeasure.shortName,
        css: isDraft ? applyStyling(data, "unitOfMeasure") : undefined,
      }),
      w: "67px",
    }),
    ...bidPackage.requests.map((request) => {
      const bidContractRevision = bidPackage.latestVersion.latestBidContractRevisions.find(
        (bcr) => bcr.bidContract.tradePartner?.id === request.tradePartner.id,
      );
      const isProratedBid =
        bidContractRevision?.bidContract?.type?.code &&
        [BidContractType.GroupedByMaterial, BidContractType.GroupedByPlanAndOption].includes(
          bidContractRevision?.bidContract?.type?.code,
        );

      return numericColumn<Row>({
        total: emptyCell,
        head: () => (
          <div css={Css.w100.df.aic.$}>
            <div css={Css.mra.$}>{request.tradePartner.name}</div>
            {!isDraft && isProratedBid && (
              <Tooltip title="Edit your prorated bid" placement="top">
                <IconButton
                  icon="estimate"
                  onClick={() => onEditProratedBid(bidContractRevision?.id)}
                  color={Palette.Blue600}
                />
              </Tooltip>
            )}
          </div>
        ),
        plan: ({ costData }) =>
          costData[request.tradePartner.id] ? (
            <div css={Css.sm.$}>
              <Price valueInCents={costData[request.tradePartner.id]} />
            </div>
          ) : (
            emptyCell
          ),
        option: ({ costData }) =>
          costData[request.tradePartner.id]
            ? priceCell({ valueInCents: costData[request.tradePartner.id] })
            : emptyCell,
        tliv: (data) => ({
          content: data.costData[request.tradePartner.id] ? (
            <Price valueInCents={data.costData[request.tradePartner.id]} />
          ) : (
            emptyCell.content
          ),
          css: isDraft ? applyStyling(data) : undefined,
        }),
      });
    }),
  ];
}

type Field = keyof Pick<
  BidPackageTakeoffLineItemVersionFragment,
  "options" | "quantity" | "unitOfMeasure" | "materialVariant" | "developmentDrop"
>;

function fieldHasChanged(data: TLIVRow["data"], field?: Field): boolean {
  if (!data.previous || !field) return false;

  if (field === "quantity") {
    return data.previous.quantity !== data.item.quantity;
  }

  if (field === "options") {
    return optionsHaveChanged(data);
  }

  const previous = data.previous[field]?.id;
  const current = data.item[field]?.id;

  return previous !== current;
}

function applyStyling(data: TLIVRow["data"], field?: Field) {
  // We want to highlight the whole row if either the row change type is added or if the options on the row have changed
  if (data.changeType === AggregateMemberVersionChangeType.ADDED || fieldHasChanged(data, "options")) {
    return Css.bgYellow50.$;
  }
  if (data.changeType === AggregateMemberVersionChangeType.REMOVED) {
    return Css.bgGray50.tdlt.gray500.$;
  }
  if (data.changeType === AggregateMemberVersionChangeType.UPDATED && fieldHasChanged(data, field)) {
    return Css.bgYellow50.$;
  }
}

function optionsHaveChanged(data: TLIVRow["data"]) {
  const prev = data.previous;
  if (!prev || data.changeType !== AggregateMemberVersionChangeType.UPDATED) return false;
  return xor(
    prev.options?.map((o) => o.id),
    data.item.options?.map((o) => o.id),
  ).nonEmpty;
}
