import { Button, Css, ScrollableParent, Step, useSnackbar, useTestIds } from "@homebound/beam";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { StepActions, stepperBarHeight, StepperProvider, useStepperContext } from "src/components/stepper";
import { useFeatureFlag } from "src/contexts/FeatureFlags/FeatureFlagContext";
import {
  FeatureFlagType,
  ScheduleDraftMode_NewlyCompletedPlanTaskFragment,
  ScheduleDraftMode_PlanTaskFragment,
  useSaveDraftPlanScheduleMutation,
} from "src/generated/graphql-types";
import { useHiddenProjectSideNav } from "src/hooks";
import { PayTradesPublishStep } from "src/routes/projects/dynamic-schedules/draft-mode/PayTradesPublishStep";
import { useDraftScheduleStore } from "src/routes/projects/dynamic-schedules/draft-mode/scheduleDraftStore";
import { createDraftScheduleUrl } from "src/RouteUrls";
import { foldEnum } from "src/utils";
import { DelayFlagPublishStep, maybeGetTaskToDelay } from "./DelayFlagPublishStep";
import { TradeConfirmPublishStep } from "./TradeConfirmPublishStep";

type PublishDraftScheduleStepperProps = {
  scheduleParentId: string;
  draftTasks: ScheduleDraftMode_PlanTaskFragment[];
  newlyCompletedPlanTasks: ScheduleDraftMode_NewlyCompletedPlanTaskFragment[];
};

export enum PublishDraftScheduleStep {
  DelayFlags = "DelayFlags",
  PayTrades = "PayTrades",
  TradeConfirm = "TradeConfirm",
}

export function PublishDraftScheduleStepper({
  scheduleParentId,
  draftTasks,
  newlyCompletedPlanTasks,
}: PublishDraftScheduleStepperProps) {
  useHiddenProjectSideNav();

  const tids = useTestIds({}, "publishDraftScheduleStepper");
  const showTradeComms = useFeatureFlag(FeatureFlagType.DynamicSchedulesTradeComms);
  const userAddedScheduleFlags = useDraftScheduleStore((state) => state.userAddedScheduleFlags);
  const getTasksWithDateOrDurationChanges = useDraftScheduleStore((state) => state.getTasksWithDateOrDurationChanges);

  const showClickToPayFlag =
    useFeatureFlag(FeatureFlagType.ClickToPay) && !newlyCompletedPlanTasks.every((ncpt) => ncpt.protoBills.isEmpty);

  // Check if there are any critical path tasks with date or duration changes or user added flags
  const delayFlagsRequireAction =
    !!maybeGetTaskToDelay(getTasksWithDateOrDurationChanges(draftTasks)) || !userAddedScheduleFlags.isEmpty;

  const initialSteps: Step[] = useMemo(() => {
    return [
      {
        label: "Add Delay Flags",
        disabled: false,
        state: delayFlagsRequireAction ? ("incomplete" as const) : ("complete" as const),
        value: PublishDraftScheduleStep.DelayFlags,
      },
      ...(showClickToPayFlag
        ? [
            {
              label: "Pay Trades",
              disabled: false,
              state: "incomplete" as const,
              value: PublishDraftScheduleStep.PayTrades,
            },
          ]
        : []),
      ...(showTradeComms
        ? [
            {
              label: "Email Trades",
              disabled: showClickToPayFlag ? true : delayFlagsRequireAction,
              state: "incomplete" as const,
              value: PublishDraftScheduleStep.TradeConfirm,
            },
          ]
        : []),
    ];
  }, [delayFlagsRequireAction, showClickToPayFlag, showTradeComms]);

  return (
    <div {...tids}>
      <StepperProvider steps={initialSteps}>
        <ScrollableParent xss={Css.mbPx(stepperBarHeight).$}>
          <PublishDraftScheduleStepperView
            scheduleParentId={scheduleParentId}
            draftTasks={draftTasks}
            newlyCompletedPlanTasks={newlyCompletedPlanTasks}
          />
        </ScrollableParent>
      </StepperProvider>
    </div>
  );
}

function PublishDraftScheduleStepperView({
  scheduleParentId,
  draftTasks,
  newlyCompletedPlanTasks,
}: PublishDraftScheduleStepperProps) {
  const { setSteps, nextStep, prevStep, isLastStep, isFirstStep, currentStep } = useStepperContext();
  const { triggerNotice } = useSnackbar();

  const [saveDraftSchedule] = useSaveDraftPlanScheduleMutation();
  const history = useHistory();

  const draftTaskChanges = useDraftScheduleStore((state) => state.draftTaskChanges);
  const requiredScheduleFlags = useDraftScheduleStore((state) => state.requiredScheduleFlags);
  const userAddedScheduleFlags = useDraftScheduleStore((state) => state.userAddedScheduleFlags);
  const draftTaskTradeC2PInput = useDraftScheduleStore((state) => state.draftTaskTradeC2PInput);
  const tparAvailabilityRequestInput = useDraftScheduleStore((state) => state.tparAvailabilityRequestInput);

  const reset = useDraftScheduleStore((state) => state.reset);

  const onBackSelect = useCallback(() => {
    isFirstStep ? history.goBack() : prevStep();
  }, [history, isFirstStep, prevStep]);

  const onPublish = useCallback(
    async (withTradeUpdates: boolean = false) => {
      await saveDraftSchedule({
        variables: {
          input: {
            scheduleParentId,
            draftTaskChanges: [
              ...draftTaskChanges,
              ...requiredScheduleFlags,
              // remove unnecessary fields from sending to the backend, merge the user added flags with draft changes
              ...userAddedScheduleFlags.map(({ taskId, taskName, title, scheduleFlagReasonType, ...others }) => ({
                id: taskId,
                scheduleFlags: [{ ...others }],
              })),
            ],
            ...draftTaskTradeC2PInput,
            ...(withTradeUpdates && {
              tradePartnerAvailabilityRequests: tparAvailabilityRequestInput,
            }),
          },
        },
      });
      reset();
      history.push(createDraftScheduleUrl(scheduleParentId));
      triggerNotice({ message: "Draft schedule changes have been saved" });
      if (withTradeUpdates) {
        triggerNotice({ message: "Scheduling emails have been sent to Trade Partners." });
      }
    },
    [
      draftTaskChanges,
      draftTaskTradeC2PInput,
      history,
      requiredScheduleFlags,
      reset,
      saveDraftSchedule,
      scheduleParentId,
      tparAvailabilityRequestInput,
      triggerNotice,
      userAddedScheduleFlags,
    ],
  );

  const updateStepStatus = useCallback(
    // Caller passing back its step value instead of using `currentStep` to prevent infinite updates from setting state
    // in a use effect
    (currentStepCompleted: boolean, currentStepValue: PublishDraftScheduleStep) => {
      setSteps((prevState) => {
        // Enable/Disable next step based on current step validity
        const currentStepIndex = prevState.findIndex((step) => step.value === currentStepValue);
        return prevState.map((step, idx) => ({
          ...step,
          ...(currentStepIndex === idx ? { state: currentStepCompleted ? "complete" : "incomplete" } : {}),
          ...(currentStepIndex + 1 === idx ? { disabled: !currentStepCompleted } : {}),
        }));
      });
    },
    [setSteps],
  );

  return (
    <>
      {foldEnum(currentStep.value, {
        [PublishDraftScheduleStep.DelayFlags]: (
          <DelayFlagPublishStep draftTasks={draftTasks} updateStepStatus={updateStepStatus} />
        ),
        [PublishDraftScheduleStep.PayTrades]: (
          <PayTradesPublishStep newlyCompletedPlanTasks={newlyCompletedPlanTasks} updateStepStatus={updateStepStatus} />
        ),
        [PublishDraftScheduleStep.TradeConfirm]: (
          <TradeConfirmPublishStep updateStepStatus={updateStepStatus} draftTasks={draftTasks} />
        ),
      })}
      <StepActions>
        <>
          <Button label="Back" onClick={onBackSelect} variant="tertiary" />
          {currentStep.value === PublishDraftScheduleStep.TradeConfirm && (
            <Button label="Skip and Publish" onClick={async () => await onPublish()} variant="tertiary" />
          )}
          <Button
            label={!isLastStep ? "Continue" : "Publish"}
            onClick={!isLastStep ? nextStep : async () => await onPublish(true)}
            size="md"
            disabled={currentStep.state !== "complete"}
          />
        </>
      </StepActions>
    </>
  );
}
