import { BoundTextAreaField, Css, Icon, Palette } from "@homebound/beam";

import { PublishBidPackageStepProps } from "../PublishBidPackagePage";

export function AddInvitationTextStep({ formState }: PublishBidPackageStepProps) {
  return (
    <div css={Css.maxw50.mxa.$}>
      <div css={Css.xl2Bd.mt4.tac.mb6.$}>Send Email to Trades</div>
      <div css={Css.bgWhite.br8.p4.pb8.bshBasic.$}>
        <div css={Css.lgBd.mb3.$}>Invitation Text</div>
        <div css={Css.gray700.mb3.$}>
          <div css={Css.df.fdr.gapPx(4).mb2.$}>
            Existing Trades
            <div css={Css.mya.$}>
              <Icon
                icon="infoCircle"
                color={Palette.Gray700}
                inc={2}
                tooltip="This Email will be sent to the previously engaged trade partners."
              />
            </div>
          </div>
          <BoundTextAreaField fullWidth field={formState.existingInvitationText} labelStyle="hidden" />
        </div>
        <div css={Css.gray700.mb3.$}>
          <div css={Css.df.fdr.gapPx(4).mb2.$}>
            New Trades
            <div css={Css.mya.$}>
              <Icon
                icon="infoCircle"
                color={Palette.Gray700}
                inc={2}
                tooltip="This Email will be sent to the the newly added trade partners."
              />
            </div>
          </div>
          <BoundTextAreaField fullWidth field={formState.invitationText} labelStyle="hidden" />
        </div>
        <div>
          <div css={Css.lgBd.mb3.$}>Bid Instructions</div>
          <BoundTextAreaField fullWidth field={formState.bidInstructionsText} labelStyle="hidden" />
        </div>
      </div>
    </div>
  );
}
