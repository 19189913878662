import { Css, RouteTabWithContent, ScrollableContent, TabContent } from "@homebound/beam";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { createProjectDashboardUrl } from "src/RouteUrls";
import { ProjectFeature } from "src/generated/graphql-types";
import { usePortalDiv } from "src/hooks";
import { useProjectTitle } from "src/hooks/useProjectTitle";
import { ActivityTab } from "src/routes/projects/dashboard/components/ActivityTab";
import { DashboardHeader } from "src/routes/projects/dashboard/components/DashboardHeader";
import { DashboardTab } from "src/routes/projects/dashboard/components/DashboardTab";
import { FinancialsTab } from "src/routes/projects/dashboard/components/Financials/FinancialsTab";
import { PreConExpectationsTab } from "src/routes/projects/dashboard/components/PreConExpectationsTab";
import { ProjectParams, projectPaths } from "src/routes/routesDef";
import { useProjectContext } from "../context/ProjectContext";

export function DashboardPage() {
  useProjectTitle("Dashboard");
  const { projectId } = useParams<ProjectParams>();
  const [portalDiv, portalRef] = usePortalDiv();
  const { features } = useProjectContext();
  const hasProductConfigPlanFeature = features.includes(ProjectFeature.ProductConfigPlan);

  const tabs: RouteTabWithContent[] = useMemo(
    () => [
      {
        name: "Dashboard",
        href: createProjectDashboardUrl(projectId),
        path: projectPaths.dashboard,
        render: () => <DashboardTab actionsPortalDiv={portalDiv} />,
      },
      {
        name: "Financials",
        href: createProjectDashboardUrl(projectId, "financials"),
        path: `${projectPaths.dashboard}/financials`,
        render: () => <FinancialsTab />,
      },
      {
        name: "Activity",
        href: createProjectDashboardUrl(projectId, "activity"),
        path: `${projectPaths.dashboard}/activity`,
        render: () => <ActivityTab />,
      },
      ...(!hasProductConfigPlanFeature
        ? [
            {
              name: "Pre-Con Expectations",
              href: createProjectDashboardUrl(projectId, "pre-con-expectations"),
              path: `${projectPaths.dashboard}/pre-con-expectations`,
              render: () => <PreConExpectationsTab />,
            },
          ]
        : []),
    ],
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId],
  );

  return (
    // Omit the default bottom padding of 16px, and add 44px of bottom padding per design request to the bottom of the tabs
    <ScrollableContent omitBottomPadding>
      <DashboardHeader tabs={tabs} headerActionsRef={portalRef} />
      <TabContent tabs={tabs} contentXss={Css.pPx(44).$} />
    </ScrollableContent>
  );
}
