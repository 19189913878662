import {
  Button,
  Chip,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Palette,
  Tag,
  ToggleChips,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Icon, SearchBox } from "src/components";
import {
  BillEditor_CommitmentLikeFragment,
  CommitmentStatus,
  NamedFragment,
  useBillEditor_CommitmentsQuery,
} from "src/generated/graphql-types";
import { commitmentStatusToTagTypeMapper, formatCentsToPrice, sortBy } from "src/utils";

type BillCommitmentsModalProps = {
  selectedProject: NamedFragment | undefined;
  selectedTradePartner: NamedFragment | undefined;
  selectedCommitments: BillEditor_CommitmentLikeFragment[];
  setSelectedCommitments: Dispatch<SetStateAction<BillEditor_CommitmentLikeFragment[]>>;
};

export function BillCommitmentsModal(props: BillCommitmentsModalProps) {
  const tid = useTestIds({});
  const { selectedProject, selectedTradePartner, selectedCommitments, setSelectedCommitments } = props;
  const [selected, setSelected] = useState<BillEditor_CommitmentLikeFragment[]>(selectedCommitments);
  const [search, setSearch] = useState("");
  const { closeModal } = useModal();
  const query = useBillEditor_CommitmentsQuery({
    variables: {
      filter: {
        projectIds: selectedProject ? [selectedProject.id] : [],
        tradePartners: selectedTradePartner ? [selectedTradePartner.id] : [],
        search,
      },
    },
    skip: !selectedProject || !selectedTradePartner,
    nextFetchPolicy: "cache-first",
  });

  const projectStage = useMemo(
    () =>
      selected.first
        ? "projectStage" in selected.first
          ? selected.first.projectStage
          : selected.first.commitment.projectStage
        : null,
    [selected],
  );

  const filteredCommitmentLikes: BillEditor_CommitmentLikeFragment[] | undefined = useMemo(() => {
    return (
      query.data?.commitments
        .filter((c) => c.status !== CommitmentStatus.Voided)
        // Once a commitment is selected, filter all commitments from the same project stage
        // this is due to backend validation forbidding bills from being cross project-stage
        .filter((c) => !projectStage || projectStage.id === c.projectStage.id)
        .flatMap((c) => [c, ...c.changeOrders.filter((cco) => cco.status !== CommitmentStatus.Voided)])
    );
  }, [query, projectStage]);

  return (
    <>
      <ModalHeader>Add Commitments</ModalHeader>
      <ModalBody virtualized>
        <div css={Css.pr3.$}>
          <div> Select commitments to be added to the bill </div>
          <ToggleChips
            values={getToggleChips(selected)}
            getLabel={({ accountingNumber }) => `#${accountingNumber}`}
            onRemove={(item) => handleToggle(item, setSelected)}
            xss={Css.my2.$}
          />
          {projectStage && (
            <div {...tid.projectStageLabel} css={Css.mb2.df.cgPx(4).$}>
              <div css={Css.mya.$}>
                <Icon icon="infoCircle" pxSize={18} />
              </div>
              Filtering commitments from Project Stage:
              <div css={Css.smBd.$}>{projectStage.stage.name}</div>
            </div>
          )}
          <div css={Css.pb2.w50.$}>
            <SearchBox
              onSearch={(term) => setSearch(term.toLowerCase())}
              placeholder="Search line items"
              fullWidth
              clearable
            />
          </div>
          <div css={Css.df.smSb.gray700.mb1.tac.$}>
            <div css={Css.mwPx(110).$}>PO Number</div>
            <div css={Css.mwPx(110).$}>Status</div>
            <div css={Css.mwPx(190).$}>Total/Unbilled Amount</div>
            <div css={Css.mwPx(100).$}>Line Items</div>
          </div>
          {query.loading ? (
            <div css={Css.tac.baseMd.$}>Loading...</div>
          ) : (
            <div css={Css.maxhPx(310).oya.$}>
              {filteredCommitmentLikes?.map((commitmentLike) => (
                <button
                  key={commitmentLike.id}
                  {...tid.itemRow}
                  onClick={() => handleToggle(commitmentLike, setSelected)}
                  css={
                    Css.df.fdr.gap1.w100.cursorPointer.ba.br4.p1.mb1.usn
                      .bc(selected.includes(commitmentLike) ? Palette.Blue700 : "lightgray")
                      .bw(selected.includes(commitmentLike) ? "2px" : "1px").$
                  }
                >
                  <div css={Css.gray700.fw6.mwPx(100).$}>
                    {commitmentLike.__typename === "Commitment" ? "PO" : "CO"} #{commitmentLike.accountingNumber}{" "}
                  </div>
                  <div css={Css.mwPx(100).$}>
                    <Tag
                      type={commitmentStatusToTagTypeMapper[commitmentLike.status]}
                      text={commitmentLike.statusText}
                    />
                  </div>
                  <div>
                    {"billingSchedule" in commitmentLike &&
                    commitmentLike.billingSchedule.some((bsi) => !bsi.isDeposit && !!bsi.commitmentDraw)
                      ? commitmentLike.billingSchedule
                          .filter((bsi) => !bsi.isDeposit)
                          .map((bs, i) => (
                            <div key={bs.commitmentDraw?.id || i} css={Css.df.$}>
                              <div css={Css.mwPx(180).ta("right").mr2.$}>
                                {formatCentsToPrice(bs.committedInCents)} (
                                {formatCentsToPrice(bs.pendingUnbilledInCents)})
                              </div>
                              <Chip
                                text={`(Draw) ${bs.commitmentDraw?.bidContractRevisionDraw?.globalPlanTask?.budgetItem?.displayName ?? ""}`}
                              />
                            </div>
                          ))
                      : commitmentLike.lineItems.map((li) => (
                          <div key={li.id} css={Css.df.$}>
                            <div css={Css.mwPx(180).ta("right").mr2.$}>
                              {formatCentsToPrice(li.costChangeInCents ?? 0)} (
                              {formatCentsToPrice(li.pendingUnbilledInCents)})
                            </div>
                            <Chip text={li.projectItem.displayName} />
                          </div>
                        ))}
                  </div>
                </button>
              ))}
              {query.data?.commitments.isEmpty && <div css={Css.tac.baseMd.$}>No matches</div>}
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={() => closeModal()} />
        <Button
          label="Add to Bill"
          disabled={selected.isEmpty && selectedCommitments.isEmpty}
          onClick={() => {
            const optionsIds = filteredCommitmentLikes?.map((co) => co.id);
            let commitmentsAndChildrenCCOs = selected;

            const selectedIds = selected.map((co) => co.id);

            selected
              .filter((co) => co.__typename === "Commitment" && co.changeOrders.nonEmpty)
              .forEach((co) => {
                // to validate type check to that is a commitment
                if (co.__typename === "Commitment") {
                  co.changeOrders.forEach((cco) => {
                    if (optionsIds?.includes(cco.id) && !selectedIds.includes(cco.id)) {
                      const childChangeOrder = filteredCommitmentLikes?.find((c) => c.id === cco.id)!;
                      commitmentsAndChildrenCCOs = [...commitmentsAndChildrenCCOs, childChangeOrder];
                      setSelected((opts) => [...opts, childChangeOrder]);
                    }
                  });
                }
              });

            setSelectedCommitments(commitmentsAndChildrenCCOs);
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}

export const getToggleChips = (selected: BillEditor_CommitmentLikeFragment[]) =>
  sortBy(selected, ({ accountingNumber }) => accountingNumber);

export const handleToggle = (
  commitment: BillEditor_CommitmentLikeFragment,
  setSelected: Dispatch<SetStateAction<BillEditor_CommitmentLikeFragment[]>>,
): void => {
  setSelected((current) =>
    current.includes(commitment) ? current.filter((c) => c !== commitment) : current.concat(commitment),
  );
};
